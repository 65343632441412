import { useIntl } from 'react-intl';
import { Button, Dialog, Flexbox, Spacer, Spacings } from '@skf-internal/ui-components-react-legacy';
import { RelationTurbine } from 'domain/index';
import WindTurbineForm from '../form/WindTurbineForm';

const WindTurbine = ({ turbineToEdit, isOpen, onFormChange, onSave, onCancel, formError }: Props) => {
  const intl = useIntl();
  const saveIsDisabled = (): boolean => (formError ? formError?.size > 0 : false);
  return (
    <>
      <Dialog cssWidth={'40rem'} cssHeight={'31rem'} feInterruptive feTitle={!!turbineToEdit.turbineId ? intl.formatMessage({ id: 'windTurbine.form.edit' }) : intl.formatMessage({ id: 'windTurbine.form.add' })} open={isOpen}>
        <WindTurbineForm
          turbine={turbineToEdit}
          onFormChange={(value: RelationTurbine) => {
            onFormChange(value);
          }}
          formError={formError}
        ></WindTurbineForm>
        <Spacer />
        <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
          <Button feType="secondary" onClick={onCancel}>
            {intl.formatMessage({ id: 'windTurbine.form.cancel' })}
          </Button>
          <Button onClick={onSave} disabled={saveIsDisabled()}>
            {intl.formatMessage({ id: 'windTurbine.form.save' })}
          </Button>
        </Flexbox>
      </Dialog>
    </>
  );
};

interface Props {
  turbineToEdit: RelationTurbine;
  isOpen: boolean;
  onFormChange: any;
  onSave: any;
  onCancel: any;
  formError?: Map<string, string>;
}

export default WindTurbine;
