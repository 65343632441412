import { Checkbox } from '@skf-internal/ui-components-react-legacy';
import { CheckboxFilterProps } from '../SearchFilter';

const CheckboxFilter = ({ values, isSelected, onSelect }: CheckboxFilterProps) => {
  return (
    <div className={values.length > 7 ? 'max-h-44 overflow-y-scroll' : ''}>
      {values.map((value, index) => (
        <Checkbox className="mb-2" key={`facet${value}${index}`} feLabel={`${value.label} (${value.count})`} onChange={(_event, checked) => onSelect(value.value, checked)} feSize="sm" checked={isSelected(value.value)} />
      ))}
    </div>
  );
};

export default CheckboxFilter;
