import { useIntl } from 'react-intl';
import { Heading, Link, Table, TableRow, Button, Icons } from '@skf-internal/ui-components-react-legacy';
import { GetEntityUrl, GetTranslationIdForEntityCategory, GetTraslationIdForEntityCategoryPlural } from 'utils';
import { RelationGenerator } from 'domain/relationships/ObjectTurbineShareDetails';
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';
import { fromGeneratorId } from '../../../../domain/shared/ObjectId';
import { stateToColor, stateToStyle } from '../entity-state';
import GeneratorTable from './table/GeneratorTable';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { EntityState } from '../entity-state';
import { SetNumberFieldErrors, SetRequiredError } from '../utils/validation/Validation.utility';
import GeneratorDialog from './dialog/GeneratorDialog';

const GeneratorParts = ({ relations, editMode, entityId, onGeneratorChange }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { changesetId } = useParams();
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
  const [generatorToEdit, setGeneratorToEdit] = useState<RelationGenerator>({} as RelationGenerator);
  const [indexToEdit, setIndexToEdit] = useState<number | null>(null);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());
  const dispatch = useDispatch<AppDispatch>();

  const handleFormChange = (value: RelationGenerator) => {
    setGeneratorToEdit((prev) => {
      return {
        ...prev,
        turbineId: entityId,
        objectId: value.objectId,
        generatorName: value.generator.generatorName,
        manufacturer: { manufacturerName: value.generator.manufacturer.manufacturerName },
        remark: value.remark,
        generator: value.generator
      };
    });
    // setFormError(() => {
    //     let errors = SetRequiredError(value.objectId, 'objectId', errors, intl);
    //     return errors;
    // })
  };

  const getMetadata = (generatorToEdit: RelationGenerator) => {
    if (generatorToEdit.metadata) {
      if (generatorToEdit.metadata.state === EntityState.NOT_MODIFIED) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else if (generatorToEdit.metadata.state === EntityState.MODIFIED) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else if (generatorToEdit.metadata.state === EntityState.NEW) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else {
        return generatorToEdit.metadata;
      }
    } else {
      return { state: EntityState.NEW_UNSAVED };
    }
  };

  return (
    <>
      <Heading as="h2">{`${intl.formatMessage({ id: GetTranslationIdForEntityCategory('Generator') })}`}</Heading>
      <GeneratorTable
        editMode={editMode}
        onDeleteGenerator={(generator: RelationGenerator, index: number) => onGeneratorChange(generator, index, 'Delete')}
        onEditGenerator={(generator: RelationGenerator, index: number) => {
          setGeneratorToEdit(generator);
          setIndexToEdit(index);
          setFormIsOpen(true);
        }}
        generators={relations}
      />

      {editMode && (
        <div className="w-full flex justify-end">
          <Button
            className="ml-auto mr-0"
            feType="secondary"
            feIcon={{ feIcon: Icons.Plus, position: 'left' }}
            onClick={() => {
              setGeneratorToEdit({ generator: { manufacturer: {} } } as RelationGenerator);
              setIndexToEdit(null);
              setFormIsOpen(true);
              setFormError(SetRequiredError(undefined, 'objectId', new Map(), intl));
            }}
          >
            {intl.formatMessage({ id: 'windTurbine.button.add' })}
          </Button>
        </div>
      )}

      <GeneratorDialog
        generatorToEdit={generatorToEdit}
        isOpen={formIsOpen}
        onFormChange={handleFormChange}
        formError={formError}
        onSave={() => {
          onGeneratorChange(
            {
              ...generatorToEdit,
              metadata: getMetadata(generatorToEdit)
            },
            indexToEdit,
            indexToEdit !== null ? 'Edit' : 'Add'
          );
          setFormIsOpen(false);
        }}
        onCancel={() => {
          setFormIsOpen(false);
          setGeneratorToEdit({ generator: { manufacturer: {} } } as RelationGenerator);
          setFormError(new Map());
          setIndexToEdit(null);
        }}
      />
    </>
  );
};

interface Props {
  relations: RelationGenerator[];
  editMode: boolean;
  entityId?: any;
  onGeneratorChange?: any;
}

export default GeneratorParts;
