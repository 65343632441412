import { css, generateId, Icon, Icons, IconSizes, Loader, Spacer, Spacings, styled } from '@skf-internal/ui-components-react-legacy';
import { UseComboboxGetComboboxPropsOptions, GetPropsCommonOptions, UseComboboxGetInputPropsOptions, UseComboboxGetToggleButtonPropsOptions } from 'downshift';

import Label from '@skf-internal/ui-components-react-legacy/dist/esm/common/components/label/Label';
import Hint from '@skf-internal/ui-components-react-legacy/dist/esm/common/components/hint/Hint';
import { baseInputStyles } from '@skf-internal/ui-components-react-legacy/dist/esm/common/styles/shared';
import { BaseSeverity } from '@skf-internal/ui-components-react-legacy/dist/esm/common/types/severity';

type ComboboxInputProps = {
  className?: string;
  label: string;
  required?: boolean;
  hint?: string;
  isLoading: boolean;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  inputValue: string;
  getComboboxProps: (options?: UseComboboxGetComboboxPropsOptions, otherOptions?: GetPropsCommonOptions) => any;
  getInputProps: (options?: UseComboboxGetInputPropsOptions, otherOptions?: GetPropsCommonOptions) => any;
  getToggleButtonProps: (options?: UseComboboxGetToggleButtonPropsOptions) => any;
  onResetClick: () => void;
  severity?: BaseSeverity;
};

const ComboboxInput = ({ className, label, required, hint, isLoading, isError, errorMessage, disabled, inputValue, getComboboxProps, getInputProps, getToggleButtonProps, onResetClick, severity }: ComboboxInputProps) => {
  const id = generateId();

  const resetButtonClickHandler = (_event: any): void => {
    onResetClick();
  };

  const mandatorySpanStyle = {
    color: 'red'
  };

  return (
    <div className={className} data-comp="search" {...getComboboxProps()}>
      <Label htmlFor={id}>
        {label} {required ? <span style={mandatorySpanStyle}>*</span> : <></>}
      </Label>
      <Spacer feSpacing={Spacings.Xs} />
      <Styled.InputSearch className={className} data-comp="input-search" {...getToggleButtonProps()}>
        <Styled.Icon feIcon={Icons.Search} feSize={IconSizes.Lg} />
        <Styled.InputSearchInput {...getInputProps()} type="search" isError={isError} disabled={disabled} />
        <Styled.InputSearchButton disabled={disabled} onClick={resetButtonClickHandler} type="reset">
          {isLoading ? <Loader feSize="sm" /> : <Icon feIcon={Icons.Close} feSize={IconSizes.Lg} />}
        </Styled.InputSearchButton>
      </Styled.InputSearch>
      {(hint || (isError && errorMessage)) && (
        <>
          <Spacer feSpacing={Spacings.Xxs} />
          <Hint feSeverity={severity ? severity : isError ? 'error' : undefined}>{isError ? (errorMessage as string) : (hint as string)}</Hint>
        </>
      )}
    </div>
  );
};

const Styled = {
  InputSearch: styled.div`
    height: fit-content;
    position: relative;
  `,

  Icon: styled(Icon)`
    left: ${Spacings.Xs};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `,

  InputSearchInput: styled.input(
    ({ isError }: { isError?: boolean }) =>
      css`
        ${baseInputStyles('md', isError ? 'error' : undefined)};
        padding-left: ${Spacings.Xxl};
        padding-right: ${Spacings.Xxl};
        width: 100%;
      `
  ),

  InputSearchButton: styled.button`
    line-height: 0;
    position: absolute;
    right: ${Spacings.Xs};
    top: 50%;
    transform: translateY(-50%);
    &:disabled {
      pointer-events: none;
    }
  `
};

export default ComboboxInput;
