import { TableCell, TableRow } from '@skf-internal/ui-components-react-legacy';
import { Changeset } from 'domain/changeset/Changeset';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import { Order, Sorting } from '../domain/SearchQuery';
import { Hit } from '../domain/SearchResult';
import useValueTranslation from './useValueTranslation';
import { ObjectType } from '../../../domain/shared/ObjectId';
import { ProductCategory } from '../../../domain';

export type AddBookmark = (id: number, category: ObjectType | ProductCategory, name: string) => void;

export type GetField = (hit: Hit, intl: IntlShape, changesets: Changeset[], addBookmark: AddBookmark) => React.ReactNode | string;

export type FieldConfig = { alias?: string; field: string; getField: GetField };

export type TableConfigs = Record<ObjectType | 'default', FieldConfig[]>;

const useTableBuilder = (objectType: ObjectType, config: TableConfigs) => {
  const sorting = useAppSelector((state) => state.searchData.query.sort ?? ({ order: Order.DESC } as Sorting));
  const intl = useIntl();
  const valueTranslator = useValueTranslation('');

  const getChildren = (children: React.ReactNode | string, field: string) => {
    if (typeof children === 'string') {
      return valueTranslator(children as string, field);
    } else {
      return children;
    }
  };
  const getRow = (hit: Hit, config: FieldConfig[], intl: IntlShape, changesets: Changeset[], addBookmark: AddBookmark): TableRow => {
    const row = { cells: [] as TableCell[] } as TableRow;
    config.forEach((c) => {
      row.cells.push({
        children: getChildren(c.getField(hit, intl, changesets, addBookmark), c.field)
      } as TableCell);
    });

    return row;
  };

  return {
    getHeader: (onSort: (field: string) => void): TableRow[] => {
      const headers = config[objectType].map((v) => getHeaderCell(intl, v, sorting, onSort));
      const width = Math.floor(100 / headers.length);
      headers.forEach((header) => (header.width = `${width}%`));
      return [{ cells: headers }];
    },
    getRows: (hits: Hit[] | undefined, changeset: Changeset[], addBookmark: AddBookmark): TableRow[] => {
      return (hits ?? []).map((h) => getRow(h, config[objectType], intl, changeset, addBookmark));
    }
  };
};

const getSortable = (field: string, sort: Sorting, onSort: (field: string) => void): { onClick: () => void; direction: 'asc' | 'desc' } => {
  const sortable = {
    onClick: () => onSort(field)
  } as { onClick: () => void; direction: 'asc' | 'desc' };
  if (field === sort.field) {
    sortable.direction = sort.order;
  }
  return sortable;
};

const getHeaderCell = (intl: IntlShape, field: FieldConfig, sort: Sorting, onSort: (field: string) => void): TableCell => {
  if (field.field) {
    return { width: '15%', children: intl.formatMessage({ id: `search.result.table.${getFieldAlias(field)}` }), scope: 'col', sortable: getSortable(field.field, sort, onSort) };
  } else {
    return { width: '15%', children: '', scope: 'col' };
  }
};

const getFieldAlias = (field: FieldConfig): string => {
  if (field.alias) {
    return field.alias;
  }
  return field.field;
};

export default useTableBuilder;
