import { Icons, Link, Time } from '@skf-internal/ui-components-react-legacy';
import { Changeset } from 'domain/index';
import { IntlShape } from 'react-intl';
import { GetTranslationIdForEntityCategory } from 'utils';
import { Gearbox, Hit, Product, Turbine, Generator } from '../domain/SearchResult';
import { AddBookmark, TableConfigs } from '../hooks/useTableBuilder';
import DesignationLinkInfo from './DesignationLinkInfo';

const getNameCell = (hit: Hit, intl: IntlShape, changesets: Changeset[]) => <DesignationLinkInfo id={hit.id} name={hit.name} objectType={hit.objectType} changesets={changesets} />;
const getObjectType = (hit: Hit, intl: IntlShape) => intl.formatMessage({ id: GetTranslationIdForEntityCategory(hit.objectType) });

const nameCell = {
  field: 'name',
  getField: getNameCell
};
const designationCell = {
  field: 'name',
  alias: 'designation',
  getField: getNameCell
};
const typeCell = {
  field: 'objectType',
  getField: getObjectType
};
const manufacturerCell = {
  field: 'manufacturer',
  getField: (hit: Hit) => hit.document.manufacturer ?? ''
};
const createdCell = {
  field: 'created',
  getField: (hit: Hit) => (!!hit.created ? <Time dateTime={new Date(hit.created).toISOString()} feFormat="date" /> : <></>)
};
const modifiedCell = {
  field: 'modified',
  getField: (hit: Hit) => (!!hit.modified ? <Time dateTime={new Date(hit.modified).toISOString()} feFormat="date" /> : <></>)
};
const addBookmarkCell = {
  field: '',
  getField: (hit: Hit, intl: IntlShape, changesets: Changeset[], addBookmark: AddBookmark) => (
    <div className="text-right">
      <Link
        as="button"
        feIcon={{ feIcon: Icons.Plus, position: 'left' }}
        onClick={() => {
          addBookmark(hit.id, hit.objectType, hit.name);
        }}
      >
        {intl.formatMessage({ id: 'search.result.bookmark' })}
      </Link>
    </div>
  )
};
const productCategoryCell = {
  field: 'productCategory',
  getField: (hit: Hit) => (hit.document as Product).productCategory ?? ''
};
const productTypeCell = {
  field: 'productType',
  getField: (hit: Hit) => (hit.document as Product).productType ?? ''
};
const gearboxTypeCell = {
  field: 'gearboxType',
  getField: (hit: Hit) => (hit.document as Gearbox).gearboxType ?? ''
};
const generatorTypeCell = {
  field: 'generatorType',
  getField: (hit: Hit) => (hit.document as Generator).generatorType ?? ''
};
const turbineTypeCell = {
  field: 'turbineType',
  getField: (hit: Hit) => (hit.document as Turbine).turbineType ?? ''
};

const config = {
  default: [nameCell, typeCell, manufacturerCell, createdCell, modifiedCell, addBookmarkCell],
  Product: [designationCell, productCategoryCell, productTypeCell, manufacturerCell, createdCell, modifiedCell, addBookmarkCell],
  Gearbox: [designationCell, gearboxTypeCell, manufacturerCell, createdCell, modifiedCell, addBookmarkCell],
  Generator: [designationCell, generatorTypeCell, manufacturerCell, createdCell, modifiedCell, addBookmarkCell],
  Turbine: [designationCell, turbineTypeCell, manufacturerCell, createdCell, modifiedCell, addBookmarkCell]
} as TableConfigs;

export default config;
