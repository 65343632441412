import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dialog, Flexbox, IconInteractive, Icons, Spacer, Spacings } from '@skf-internal/ui-components-react-legacy';
import { useState } from 'react';

const RemoveUserRequestAttachmentButton = ({ name, onRemove }: Props) => {
  const intl = useIntl();
  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Dialog feTitle={intl.formatMessage({ id: 'ticket.attachments.delete' })} open={isDialogOpen} feOnClose={() => setDialogOpen(false)}>
        <FormattedMessage id={'ticket.attachments.delete.confirmation'} values={{ name }} />
        <Spacer />
        <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
          <Button feType="secondary" onClick={() => setDialogOpen(false)}>
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
          <Button onClick={onRemove}>{intl.formatMessage({ id: 'button.confirm' })}</Button>
        </Flexbox>
      </Dialog>
      <IconInteractive aria-label={intl.formatMessage({ id: 'ticket.attachments.delete' })} as={'button'} feIcon={Icons.Trash} title={intl.formatMessage({ id: 'ticket.attachments.delete' })} onClick={() => setDialogOpen(true)} />
    </>
  );
};

interface Props {
  name: string;
  onRemove: () => void;
}

export default RemoveUserRequestAttachmentButton;
