import { Heading, Link, LinkProps, Table, TableRow, Tag } from '@skf-internal/ui-components-react-legacy';
import { useNavigate } from 'react-router';
import { AnyDifference, DifferenceType } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetEntityUrl, GetTraslationIdForChangesetDifferenceType } from 'utils/index';
import { ChangeLog, ChangeLogStockingPointItem, ChangeLogValueItem, ChangeLogObjectDetailsItem } from '../..';
import _ from 'lodash';
import { asObjectId } from '../../../../../domain/shared/ObjectId';
import ChangeLogTurbineDetails from './change-log/ChangeLogTurbineDetails';

const ChangesTable = ({ changesetId, differences }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: intl.formatMessage({ id: 'changeset.changesTable.name' }), scope: 'col', sortable: { sortBy: ({ children }: LinkProps) => children } },
        { children: intl.formatMessage({ id: 'changeset.changesTable.changelog' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'changeset.changesTable.tags' }), scope: 'col' }
      ]
    }
  ];

  const getChangelog = (difference: AnyDifference): JSX.Element => {
    switch (difference.type) {
      case DifferenceType.NEW:
        return (
          <>
            {difference.properties?.map((field) => (
              <ChangeLog key={field.name} modifiedAt={field.modifiedAt} modifiedBy={field.modifiedBy} category={difference.ref.type} name={field.name}>
                <ChangeLogValueItem name={field.name} newValue={field.newValue} />
              </ChangeLog>
            ))}
          </>
        );
      case DifferenceType.DELETED:
        return <ChangeLog modifiedAt={difference.modifiedAt} modifiedBy={difference.modifiedBy} category={difference.ref.type} />;
      case DifferenceType.PROPERTIES_UPDATED:
        return (
          <>
            {difference.properties?.map((field) => (
              <ChangeLog key={field.name} modifiedAt={field.modifiedAt} modifiedBy={field.modifiedBy} category={difference.ref.type} name={field.name}>
                <ChangeLogValueItem name={field.name} oldValue={field.oldValue} newValue={field.newValue} />
              </ChangeLog>
            ))}
          </>
        );
      case DifferenceType.STOCKING_POINTS_UPDATED:
        return (
          <ChangeLog modifiedAt={difference.modifiedAt} modifiedBy={difference.modifiedBy} category={difference.ref.type} name="stockingPoint">
            <ChangeLogStockingPointItem added={difference.added} removed={difference.removed} />
          </ChangeLog>
        );
      case DifferenceType.OBJECT_DETAILS_UPDATED:
        return <ChangeLogObjectDetailsItem added={difference.added} updated={difference.updated} removed={difference.removed} type={difference.ref.type} />;
      case DifferenceType.TURBINE_DETAILS:
        return (
          <ChangeLog modifiedAt={difference.modifiedAt} modifiedBy={difference.modifiedBy} category={difference.ref.type}>
            <ChangeLogTurbineDetails differences={difference} type={difference.ref.type} />
          </ChangeLog>
        );
      default:
        return <></>;
    }
  };

  const getTagColor = (state: DifferenceType): 'yellow' | 'red' | undefined => {
    switch (state) {
      case DifferenceType.PROPERTIES_UPDATED:
      case DifferenceType.STOCKING_POINTS_UPDATED:
      case DifferenceType.OBJECT_DETAILS_UPDATED:
      case DifferenceType.TURBINE_DETAILS:
        return 'yellow';
      case DifferenceType.DELETED:
        return 'red';
      default:
        return undefined;
    }
  };

  const tableBody: TableRow[] = differences.map((difference) => ({
    cells: [
      {
        width: '20%',
        className: 'align-top',
        children: <Link as="a" href={GetEntityUrl(asObjectId(difference.ref.id, difference.ref.type), changesetId)} onClick={(_, route) => navigate(route)}>{`${difference.ref.type} - ${difference.ref.name}`}</Link>
      },
      { width: '65%', className: 'align-top whitespace-normal', children: <div>{getChangelog(difference)}</div> },
      { width: '15%', className: 'align-top', children: <Tag feColor={getTagColor(difference.type)}>{intl.formatMessage({ id: GetTraslationIdForChangesetDifferenceType(difference.type) })}</Tag> }
    ]
  }));

  return (
    <>
      {!_.isEmpty(differences) && (
        <div className="mb-2">
          <Heading as="h3" className="mb-4">
            {intl.formatMessage({ id: 'changeset.changesTable.heading' })}
          </Heading>
          <Table feBody={tableBody} feHead={tableHead} feScrollable />
        </div>
      )}
    </>
  );
};

interface Props {
  changesetId: string;
  differences: AnyDifference[];
}

export default ChangesTable;
