import { Card, Link } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

const ChangesetNotFound = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <div className="pb-5">
      <Card>
        <div>
          <p className="mb-2">{intl.formatMessage({ id: 'changeset.not.found' })}</p>
          <Link as="a" href="/" onClick={(_, route) => navigate(route)}>
            {intl.formatMessage({ id: 'changeset.not.found.link' })}
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default ChangesetNotFound;
