import { Icons, Link, Icon, IconColors } from '@skf-internal/ui-components-react-legacy';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { EntityExistsInAChangeset, GetEntityUrl } from 'utils/index';
import { Changeset } from 'domain/index';
import { asObjectId, ObjectType } from '../../../domain/shared/ObjectId';

const SearchResultTable = ({ id, name, objectType, changesets }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Link
        className="mr-2"
        as="button"
        children={name}
        onClick={() => {
          navigate(GetEntityUrl(asObjectId(id, objectType)));
        }}
      />
      {EntityExistsInAChangeset(changesets, id) && <Icon feIcon={Icons.InfoCircleFilled} feColor={IconColors.Blue} title={intl.formatMessage({ id: 'existsInChangesetInfo' })} />}
    </>
  );
};

interface Props {
  id: number;
  name: string;
  objectType: ObjectType;
  changesets: Changeset[];
}

export default SearchResultTable;
