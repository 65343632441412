import { getObjectTypeFromRecordCategory } from './domain/ObjectType';
import { SuggestHit } from './domain/SuggestHit';
import useSuggestSearch from './hooks/useSuggestSearch';
import ComboBox from 'components/form/ComboBox';
import { useEffect, useMemo, useState } from 'react';
import { ComboBoxItem } from 'components/form/ComboBox/ComboBoxItem';
import useDebounce from './hooks/useDebounce';
import { DesignationInputProps } from './domain/DesignationInputProps';
import { Icons } from '@skf-internal/ui-components-react-legacy';
import { IntlShape, useIntl } from 'react-intl';
import { ObjectType } from '../../domain/shared/ObjectId';

export type DesignationItem = {
  id: number;
  name: string;
  manufacturerName?: string;
};

const getComboBoxItem = (hit: SuggestHit): ComboBoxItem<SuggestHit> => {
  return {
    item: hit,
    label: hit.name,
    value: hit.id.toString()
  };
};

const getComboBoxItemFromDesignationItem = (value: DesignationItem, objectType: ObjectType): ComboBoxItem<SuggestHit> => {
  return {
    item: {
      id: value.id,
      name: value.name,
      manufacturer: value.manufacturerName,
      objectType
    },
    label: value.name,
    value: value.id.toString(),
    feIcon: Icons.InfoCircleFilled
  };
};

const getSearchHit = (hit: ComboBoxItem<SuggestHit>): DesignationItem => {
  return {
    id: hit.item.id,
    name: hit.item.name,
    manufacturerName: hit.item.manufacturer
  };
};

const appendHeaderToAdditionalItems = (additionalItems: DesignationItem[], objectType: ObjectType, intl: IntlShape): ComboBoxItem<SuggestHit>[] => {
  let additionalSelectItems = additionalItems?.map((i) => getComboBoxItemFromDesignationItem(i, objectType));
  if (additionalSelectItems && additionalSelectItems.length > 0) {
    additionalSelectItems = [
      {
        item: {
          name: intl.formatMessage({ id: 'search.designation.from.changeset' }),
          id: 0,
          objectType: ObjectType.GEARBOX
        },
        disabled: true,
        label: intl.formatMessage({ id: 'search.designation.from.changeset' }),
        value: ''
      },
      ...additionalSelectItems
    ];
  }
  return additionalSelectItems;
};

const DesignationComboBox = ({ recordCategory, label, value, onChange, additionalItems = [] }: DesignationInputProps) => {
  const { objectType, productCategory } = getObjectTypeFromRecordCategory(recordCategory);
  const intl = useIntl();
  const suggest = useSuggestSearch(objectType, productCategory);

  const [searchText, setSearchText] = useState<string | undefined>();
  const [selectedItem, setSelectedItem] = useState<ComboBoxItem<SuggestHit> | null>(value ? getComboBoxItemFromDesignationItem(value, objectType) : null);

  let additionalSelectItems = useMemo(() => appendHeaderToAdditionalItems(additionalItems, objectType, intl), [additionalItems, objectType, intl]);

  const [items, setItems] = useState<ComboBoxItem<SuggestHit>[]>(additionalSelectItems);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);

  useEffect(() => {
    if (debouncedSearchText) {
      setIsLoading(true);
      suggest(debouncedSearchText)
        .then((result) => {
          const items = result.map((s) => getComboBoxItem(s as SuggestHit));
          setItems(items);
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [debouncedSearchText, suggest, objectType]);

  const onItemSelected = (item: ComboBoxItem<SuggestHit> | null | undefined): void => {
    if (item) {
      setSelectedItem(item);
      onChange(getSearchHit(item));
    }
  };

  const onSearch = (value?: string | undefined) => {
    setSearchText(value);
  };

  const onReset = () => {
    onChange(null);
    setItems(additionalSelectItems);
  };

  return <ComboBox<SuggestHit> required={true} className="w-full" items={items} label={label} isError={isError} isLoading={isLoading} onItemSelected={onItemSelected} onSearch={onSearch} onReset={onReset} currentItem={selectedItem} />;
};

export default DesignationComboBox;
