import { Loader } from '@skf-internal/ui-components-react-legacy';

///NOTE - parent element need to set relative as className
const CoverLoader = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <div className="absolute w-full h-full top-0 flex justify-center bg-gray-100 opacity-60">
      <Loader className="self-center" />
    </div>
  ) : (
    <></>
  );
};

export default CoverLoader;
