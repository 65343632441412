import { Card, Heading, Loader, Spacer, Tabs, useToast } from '@skf-internal/ui-components-react-legacy';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from 'store/index';
import { GetEntityUrl, GetTranslationIdForEntityCategory, ObjectComparison, ProductPartsComparison, TurbineShareDetailsComparison } from 'utils/index';
import { Generator, PositionsCategory, ProductCategory, RecordCategory, RelationshipProduct, RelationTurbine, State } from 'domain/index';
import {
  ExistsInChangesetInfo,
  FormActions,
  getChangesetDetails,
  GetHasChangesOnParts,
  getRelatedComponents,
  Header,
  ListOfParts,
  LogPosts,
  RemovedOverlay,
  resetChangesetDetailsState,
  resetPositionsState,
  resetProductParts,
  resetProductPartsState,
  SetNumberFieldErrors,
  setProductParts,
  setProductPartsMetadataStateAsSaved,
  SetRequiredError,
  SetStringMaxErrors,
  WindTurbine
} from '../shared/index';
import GeneratorForm from './form/GeneratorForm';
import { deleteGenerator, editInTicket, getGenerator, putChanges, resetGeneratorState, resetWarning, setEditMode, setGenerator } from './store/generator-slice';
import { getLog, resetLogState } from '../shared';
import { getRelatedTurbines, resetRelationTurbinesState, resetTurbines, setTurbines } from '../shared/store/relation-turbines';
import _ from 'lodash';
import EntityStateIndicator from '../shared/entity-state/EntityStateIndicator';
import { fromGeneratorId } from '../../../domain/shared/ObjectId';
import EditWarnings from '../shared/edit-errors/EditWarnings';

const EditGenerator = ({ newMode, setLoadForms }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const generatorId = Number(id);
  const { changesetId } = useParams();
  const [searchParams] = useSearchParams();
  const { generator, generatorOriginal } = useAppSelector((state) => state.editGeneratorData);
  const { eTag, editMode, loading, initError, error, warnings } = useAppSelector((state) => state.editGeneratorData);
  const { loading: loadingLogPosts, logPosts, logPostsAreInitialized, error: errorLogPosts } = useAppSelector((state) => state.logData);
  const { changesetDetails, error: errorChangesetDetails } = useAppSelector((state) => state.changesetDetailsData);
  const { loading: loadingProductParts, productParts, productPartsOriginal, productPartsAreInitialized, error: errorProductParts } = useAppSelector((state) => state.relationshipProductsData);
  const { loading: loadingTurbines, turbines, turbinesOriginal, turbinesAreInitialized, error: errorTurbines } = useAppSelector((state) => state.relationTurbinesData);
  const changesets = useAppSelector((state) => state.changesetData.changesets);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());
  const currentEtag = useAppSelector((state) => state.changesetData.currentETag) || '';

  useEffect(() => {
    dispatch(resetRelationTurbinesState());
    dispatch(resetGeneratorState());
    dispatch(resetLogState());
    dispatch(resetProductPartsState());
    dispatch(resetPositionsState());
    updateChangesetDetails(changesetId);
    if (!newMode) {
      dispatch(getGenerator({ generatorId, changesetId: changesetId }));
      dispatch(getRelatedTurbines({ type: 'Generator', id: generatorId, changesetId }));
    }
  }, [generatorId, changesetId]);

  useEffect(() => {
    if (newMode) {
      dispatch(setEditMode({ editMode: true }));
    } else {
      dispatch(setEditMode({ editMode: !!searchParams.get('editMode') }));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!!error) handleError(error);
    if (!!errorLogPosts) handleError(errorLogPosts);
    if (!!errorChangesetDetails) handleError(errorChangesetDetails);
    if (!!errorProductParts) handleError(errorProductParts);
    if (!!errorTurbines) handleError(errorTurbines);
  }, [error, errorLogPosts, errorChangesetDetails, errorProductParts, errorTurbines]);

  const handleError = (message: string) => {
    addToast({ children: message, feSeverity: 'error' });
    console.log(message);
  };

  if (loading.generator) {
    return <Loader className="mr-auto ml-auto" />;
  } else if (initError || !generator) {
    return <p className="p-5">{intl.formatMessage({ id: 'editTurbinePage.error' })}</p>;
  }

  const isAutoCommit = (): boolean => (newMode ? false : !changesetId);

  const handleGeneratorFormChange = (value: Generator) => {
    setFormError((prev) => SetRequiredError(value.generatorName, 'generatorName', prev, intl));
    setFormError((prev) => SetRequiredError(value.generatorType, 'generatorType', prev, intl));
    setFormError((prev) => SetRequiredError(value.power, 'power', prev, intl));
    setFormError((prev) => SetStringMaxErrors(value.remark, 'remark', 500, prev, intl));
    setFormError((prev) => SetRequiredError(value.manufacturerId, 'manufacturerName', prev, intl));

    setFormError((prev) => SetNumberFieldErrors(value.power, 'power', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.gridFrequency, 'gridFrequency', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.nominalRpmHigh, 'nominalRpmHigh', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.nominalRpmLow, 'nominalRpmLow', Number.MAX_SAFE_INTEGER, prev, intl));

    dispatch(setGenerator({ generator: value }));
  };

  const onTicketActionClick = async (changesetId: string, action: 'edit' | 'open') => {
    let currentChangesetId: string | undefined = changesetId;
    if (action === 'edit') {
      currentChangesetId = await dispatch(editInTicket({ entityId: generator.generatorId, generatorName: generator.generatorName ?? '', changesetId })).unwrap();
    }
    navigate(GetEntityUrl(fromGeneratorId(generator.generatorId), currentChangesetId, action === 'edit'));
  };

  const onSave = async (event: any) => {
    const generatorDiff = ObjectComparison(generator, generatorOriginal);
    const [addTurbineDetails, updateTurbineDetails, removeTurbineDetails] = TurbineShareDetailsComparison(turbines, turbinesOriginal);
    const [addObjectDetails, updateObjectDetails, removeObjectDetails] = ProductPartsComparison(productParts, productPartsOriginal);
    const response = await dispatch(
      putChanges({
        entityId: generator.generatorId,
        changesetId,
        generatorDiff,
        additionalChanges: { addObjectDetails, updateObjectDetails, removeObjectDetails, addTurbineDetails, updateTurbineDetails, removeTurbineDetails },
        isAutoCommit: isAutoCommit(),
        etag: newMode && changesetId ? currentEtag : eTag,
        newMode
      })
    ).unwrap();
    console.log(response);
    dispatch(setProductPartsMetadataStateAsSaved());
    if (response?.status === 'ok') {
      addToast({ children: 'Generator is saved successfully', feSeverity: 'success' });
      const redirectUrl = isAutoCommit() ? `/generator/${response.entityId}` : `/generator/${response.entityId}/${response.changesetId}`;
      navigate(redirectUrl);
      dispatch(getRelatedTurbines({ type: 'Generator', id: response.entityId, changesetId }));
    }
  };

  const updateChangesetDetails = (changesetId: string | undefined) => {
    if (changesetId) {
      dispatch(getChangesetDetails({ changesetId }));
    } else {
      dispatch(resetChangesetDetailsState());
    }
  };

  const onClose = (event: any) => {
    navigate(GetEntityUrl(fromGeneratorId(generator.generatorId)));
  };

  const onCancelAdd = () => {
    setLoadForms(false);
  };

  const onDelete = () => dispatch(deleteGenerator({ changesetId, generatorId: generator.generatorId, eTag }));

  return (
    <>
      <EditWarnings entityId={generatorId} warnings={warnings} closeWarning={(warning) => dispatch(resetWarning(warning))} />
      <Card feNoPadding>
        <div className="relative">
          <RemovedOverlay show={changesetDetails?.refs.find((x) => x.id === generator.generatorId)?.state === State.DELETED} />
          <div className={!changesetId ? 'p-8' : ''}>
            <Header changesetId={changesetId} objectId={fromGeneratorId(generator.generatorId)}></Header>
            <div className={changesetId ? 'p-8' : ''}>
              <div className="flex pl-2">
                <EntityStateIndicator state={generator.metadata?.state} />
                <Heading as="h1">
                  {`${intl.formatMessage({ id: GetTranslationIdForEntityCategory('Generator') }).toUpperCase()}`} {generator.generatorName ? `- ${generator.generatorName}` : <></>}
                </Heading>
              </div>
              <Spacer />
              <GeneratorForm editMode={editMode} formError={formError} generator={generator} onGeneratorChange={handleGeneratorFormChange} changesetDetails={changesetDetails} />
              <Tabs
                onClick={(_, index) => {
                  if (index === 0 && !turbinesAreInitialized && !newMode) dispatch(getRelatedTurbines({ type: 'Generator', id: generator.generatorId }));
                  if (index === 1 && !productPartsAreInitialized && !newMode) dispatch(getRelatedComponents({ type: 'Generator', id: generator.generatorId, changesetId }));
                  if (index === 2 && !logPostsAreInitialized && !newMode) dispatch(getLog({ type: 'Generator', id: generator.generatorId }));
                }}
                className="mt-2 mb-4"
                feItems={[
                  {
                    children: (
                      <>
                        {loadingTurbines ? (
                          <Loader />
                        ) : (
                          <WindTurbine
                            entityId={generator.generatorId}
                            windTurbines={turbines}
                            editMode={editMode}
                            onTurbineChange={(value: RelationTurbine, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setTurbines({ value, index, action }))}
                          ></WindTurbine>
                        )}
                      </>
                    ),
                    id: '0',
                    label: `${intl.formatMessage({ id: 'editGenertor.tab.windTurbine' }).toUpperCase()}`
                  },
                  {
                    children: (
                      <>
                        {loadingProductParts ? (
                          <Loader />
                        ) : (
                          <>
                            <ListOfParts
                              entityId={generator.generatorId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.BEARING}
                              recordCategory={RecordCategory.Bearing}
                              positionCategory={PositionsCategory.GENERATOR}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={generator.generatorId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.GREASE}
                              recordCategory={RecordCategory.Grease}
                              positionCategory={PositionsCategory.GENERATOR}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                          </>
                        )}
                      </>
                    ),
                    id: '1',
                    label: `${intl.formatMessage({ id: 'editGenertor.tab.listOfParts' }).toUpperCase()}${GetHasChangesOnParts(generator.generatorId, [], changesetDetails) ? ` *` : ''}`
                  },
                  // Currently not fully implemented.
                  // {
                  //   children: (
                  //     <PositionSetup positions={positions} editMode={editMode} onPositionChange={(value: Position, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setPositions({ value, index, action }))}></PositionSetup>
                  //   ),
                  //   id: '2',
                  //   label: `${intl.formatMessage({ id: 'editGenertor.tab.positionsSetup' }).toUpperCase()}`
                  // },
                  {
                    id: '3',
                    children: <LogPosts loading={loadingLogPosts} logPosts={logPosts} />,
                    label: intl.formatMessage({ id: 'log.tab' }).toUpperCase()
                  }
                ]}
                feType="expanded"
              />
              <ExistsInChangesetInfo isEditingInTicket={!!changesetId} changesets={changesets} entityId={generator.generatorId} />
              <FormActions
                entityId={generator.generatorId}
                editMode={editMode}
                formError={formError}
                loading={{ delete: loading.delete, editInTicket: loading.editInTicket, save: loading.save }}
                formHasChanges={!(_.isEqual(generator, generatorOriginal) && _.isEqual(productParts, productPartsOriginal) && _.isEqual(turbines, turbinesOriginal))}
                changesetId={changesetId}
                onTicketActionClick={onTicketActionClick}
                onEditClick={() => dispatch(setEditMode({ editMode: true }))}
                onSaveClick={onSave}
                onCloseChangesetClick={onClose}
                onDeleteClick={onDelete}
                onCancelClick={() => {
                  dispatch(setEditMode({ editMode: false }));
                  dispatch(setGenerator({ generator: { ...generatorOriginal } }));
                  dispatch(resetProductParts());
                  dispatch(resetTurbines());
                  dispatch(resetPositionsState());
                }}
                newMode={newMode}
                onCancelAdd={onCancelAdd}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EditGenerator;

interface Props {
  newMode?: boolean;
  setLoadForms?: any;
}
