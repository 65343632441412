import { Flexbox, Pagination, Table, TableRow, TableRowInteractive, Tag, Time, TimeProps } from '@skf-internal/ui-components-react-legacy';
import { Changeset, Refs, State } from 'domain/index';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { GetNameFromEmail, GetTraslationIdForChangesetState, GetTranslationIdForEntityCategory, IsUserRequest, GetColorBorderForStatus } from 'utils/index';
import UserRequestTag from '../../shared/user-request-tag/UserRequestTag';
import { TableCellSortable } from '@skf-internal/ui-components-react-legacy/dist/esm/components/table/TableCell';

const ChangesetTable = ({ changesets, onSort, sortField, sortDirection, pagination }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const getFormattedChangeTypes = (refs: Refs[], isUserRequest: boolean): React.ReactNode => {
    const tags = _.uniqBy(refs, 'state').map((x) => (
      <Tag key={x.state} className="mt-1" feSize="sm" feColor={getTagColor(x.state)}>
        {intl.formatMessage({ id: GetTraslationIdForChangesetState(x.state ?? '') })}
      </Tag>
    ));
    if (isUserRequest) {
      tags.unshift(<UserRequestTag key="USERREQUEST" />);
    }
    return <div className="max-w-itemsCol flex flex-col">{tags}</div>;
  };

  const getTagColor = (state: State): 'green' | 'orange' | 'red' | undefined => {
    switch (state) {
      case State.NEW:
        return 'green';
      case State.MODIFIED:
        return 'orange';
      case State.DELETED:
        return 'red';
      case State.EMPTY:
        return undefined;
    }
  };

  const getSortable = (defaultSort: TableCellSortable | undefined, fieldName: string): TableCellSortable | undefined => {
    if (onSort) {
      if (fieldName === 'skip') {
        return undefined;
      }
      return {
        onClick: () => {
          onSort(fieldName);
        },
        direction: fieldName === sortField ? sortDirection : undefined
      };
    } else {
      return defaultSort;
    }
  };

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: intl.formatMessage({ id: 'changeset.table.title' }), scope: 'col', sortable: getSortable({}, 'skip') },
        { children: intl.formatMessage({ id: 'changeset.table.items' }), scope: 'col', sortable: getSortable(undefined, 'refTypes') },
        { children: intl.formatMessage({ id: 'changeset.table.date' }), scope: 'col', sortable: getSortable({ sortBy: (props: TimeProps) => props?.dateTime }, 'createdAt') },
        { children: intl.formatMessage({ id: 'changeset.table.createdBy' }), scope: 'col', sortable: getSortable({}, 'createdByUserName') },
        { children: intl.formatMessage({ id: 'changeset.table.modifiedBy' }), scope: 'col', sortable: getSortable({}, 'lastModifiedByUserName') },
        { children: intl.formatMessage({ id: 'changeset.table.tags' }), scope: 'col', sortable: getSortable({}, 'skip') }
      ]
    }
  ];

  const tableBody: TableRowInteractive[] = changesets.map((changeset) => ({
    cells: [
      { width: '10%', children: <div className={'border-l-8 pl-2 ' + GetColorBorderForStatus(changeset.status)}>{changeset.changesetId.slice(0, 5)}</div> },
      {
        width: '15%',
        children: (
          <div className="max-w-itemsCol overflow-hidden">
            {changeset.refs.map((ref) => (
              <div key={ref.id}>
                <p className="mt-1 mb-1">{`${intl.formatMessage({ id: GetTranslationIdForEntityCategory(ref.type ?? '') })} ${ref.name}`}</p>
              </div>
            ))}
          </div>
        )
      },
      { width: '10%', children: <Time dateTime={changeset.createdAt} feFormat="date" /> },
      { width: '10%', children: GetNameFromEmail(changeset.createdBy) },
      { width: '10%', children: GetNameFromEmail(changeset.lastModifiedBy) },
      { width: '10%', children: getFormattedChangeTypes(changeset.refs, IsUserRequest(changeset.userRequest)) }
    ],
    onClick: () => navigate(`/tickets/${changeset.changesetId}`)
  }));

  return (
    <>
      <div className="h-96 my-4">
        <Table feHead={tableHead} feBody={tableBody} feCompact feScrollable feInteractive></Table>
      </div>
      {pagination && (
        <Flexbox feJustifyContent="flex-end">
          <Pagination
            feControls={{
              next: {
                'aria-label': intl.formatMessage({ id: 'changeset.table.nextPage' })
              },
              prev: {
                'aria-label': intl.formatMessage({ id: 'changeset.table.previousPage' })
              }
            }}
            feDelimiter={intl.formatMessage({ id: 'changeset.table.of' })}
            feOnPageChange={(page: number) => pagination.setCurrentPage(page)}
            fePage={pagination.currentPage}
            feItemsPerPage={pagination.pageSize}
            feTotal={pagination.totalSize}
          />
        </Flexbox>
      )}
    </>
  );
};

interface Props {
  changesets: Changeset[];
  onSort?: (column: string) => void;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  pagination?: PaginationProps;
}

export interface PaginationProps {
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  totalSize: number;
}

export default ChangesetTable;
