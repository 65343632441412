import { FerrisProvider, PopoverProvider, ToastProvider } from '@skf-internal/ui-components-react-legacy';
import MainLayout from 'components/layout/MainLayout';
import AuthProvider from 'features/authentication/AuthProvider';
import LoginPage from 'features/authentication/LoginPage';
import ChangesetListPage from 'features/changeset/list/ChangesetListPage';
import SearchPage from 'features/search/SearchPage';
import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from 'features/not-found/PageNotFound';
import ChangesetItemPage from 'features/changeset/item/ChangesetItemPage';
import NotAllowedPage from 'features/not-allowed/NotAllowedPage';
import EditTurbine from 'features/edit/edit-turbine/EditTurbine';
import EditGearbox from 'features/edit/edit-gearbox/EditGearbox';
import EditGenerator from 'features/edit/edit-generator/EditGenerator';
import EditProduct from 'features/edit/edit-product/EditProduct';
import TwoColumnTicketLayout from 'features/ticket-layout/TwoColumnTicketLayout';
import PageLayout from 'components/layout/PageLayout';
import AddProduct from 'features/add/AddProduct';

const App = () => {
  return (
    <AuthProvider>
      <IntlProvider locale="en" messages={{ ...require(`assets/translations/en.json`) }}>
        <FerrisProvider>
          <ToastProvider>
            <PopoverProvider>
              <Routes>
                <Route element={<MainLayout />}>
                  <Route
                    path="/"
                    element={
                      <TwoColumnTicketLayout>
                        <ChangesetListPage />
                      </TwoColumnTicketLayout>
                    }
                  />
                  <Route path="/search" element={<SearchPage />} />
                  <Route
                    path="/tickets/:changesetId"
                    element={
                      <TwoColumnTicketLayout>
                        <ChangesetItemPage />
                      </TwoColumnTicketLayout>
                    }
                  />
                  <Route
                    path="/product/:id/"
                    element={
                      <TwoColumnTicketLayout>
                        <EditProduct newMode={false} />
                      </TwoColumnTicketLayout>
                    }
                  >
                    <Route
                      path=":changesetId"
                      element={
                        <TwoColumnTicketLayout>
                          <EditProduct newMode={false} />
                        </TwoColumnTicketLayout>
                      }
                    ></Route>
                  </Route>
                  <Route
                    path="/generator/:id/"
                    element={
                      <TwoColumnTicketLayout>
                        <EditGenerator />
                      </TwoColumnTicketLayout>
                    }
                  >
                    <Route
                      path=":changesetId"
                      element={
                        <TwoColumnTicketLayout>
                          <EditGenerator />
                        </TwoColumnTicketLayout>
                      }
                    ></Route>
                  </Route>
                  <Route
                    path="/gearbox/:id/"
                    element={
                      <TwoColumnTicketLayout>
                        <EditGearbox />
                      </TwoColumnTicketLayout>
                    }
                  >
                    <Route
                      path=":changesetId"
                      element={
                        <TwoColumnTicketLayout>
                          <EditGearbox />
                        </TwoColumnTicketLayout>
                      }
                    ></Route>
                  </Route>
                  <Route
                    path="/turbine/:id/"
                    element={
                      <TwoColumnTicketLayout>
                        <EditTurbine />
                      </TwoColumnTicketLayout>
                    }
                  >
                    <Route
                      path=":changesetId"
                      element={
                        <TwoColumnTicketLayout>
                          <EditTurbine />
                        </TwoColumnTicketLayout>
                      }
                    ></Route>
                  </Route>
                  <Route
                    path="/add-new/"
                    element={
                      <TwoColumnTicketLayout>
                        <AddProduct />
                      </TwoColumnTicketLayout>
                    }
                  >
                    <Route
                      path=":changesetId"
                      element={
                        <TwoColumnTicketLayout>
                          <AddProduct />
                        </TwoColumnTicketLayout>
                      }
                    ></Route>
                  </Route>
                </Route>
                <Route path="*" element={<PageNotFound />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/not-allowed" element={<NotAllowedPage />} />
              </Routes>
            </PopoverProvider>
          </ToastProvider>
        </FerrisProvider>
      </IntlProvider>
    </AuthProvider>
  );
};

export default App;
