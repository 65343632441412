import { Heading, Icons, Link, LinkProps, Table, TableRow, Text } from '@skf-internal/ui-components-react-legacy';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { State, Refs } from 'domain/index';
import { GetEntityUrl, GetTranslationIdForEntityCategory } from 'utils/index';
import _ from 'lodash';
import { asObjectId } from '../../../../../domain/shared/ObjectId';

const EmptyRefsTable = ({ changesetId, refs, onDeleteRefClick }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const emptyRefs = refs.filter((x) => x.state === State.EMPTY);

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: intl.formatMessage({ id: 'changeset.emptyRefsTable.designation' }), scope: 'col', sortable: { sortBy: ({ children }: LinkProps) => children } },
        { children: intl.formatMessage({ id: 'changeset.emptyRefsTable.information' }), scope: 'col' },
        { children: '', scope: 'col' }
      ]
    }
  ];

  const tableBody: TableRow[] = emptyRefs.map((ref) => ({
    cells: [
      { children: <Link as="a" href={GetEntityUrl(asObjectId(ref.id, ref.type), changesetId)} onClick={(_, route) => navigate(route)}>{`${intl.formatMessage({ id: GetTranslationIdForEntityCategory(ref.type) })} - ${ref.name}`}</Link> },
      { children: <Text className="max-w-lg whitespace-normal">{intl.formatMessage({ id: 'changeset.info.noChanges' })}</Text> },
      {
        children: (
          <Link as="button" onClick={() => onDeleteRefClick(ref.id)} feIcon={{ feIcon: Icons.Trash, position: 'left' }}>
            {intl.formatMessage({ id: 'changeset.remove.button' })}
          </Link>
        ),
        style: { textAlign: 'right' }
      }
    ]
  }));

  return (
    <>
      {!_.isEmpty(emptyRefs) && (
        <div className="mb-2">
          <Heading as="h3" className="mb-4">
            {intl.formatMessage({ id: 'changeset.emptyRefsTable.heading' })}
          </Heading>
          <Table feBody={tableBody} feHead={tableHead} feScrollable />
        </div>
      )}
    </>
  );
};

interface Props {
  changesetId: string;
  refs: Refs[];
  onDeleteRefClick: any;
}

export default EmptyRefsTable;
