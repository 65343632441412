import { Button, Dialog, Flexbox, Spacer, Spacings } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';

const RejectDialog = ({ isOpen, onClose, onConfirm }: Props) => {
  const intl = useIntl();

  return (
    <Dialog feInterruptive feTitle={intl.formatMessage({ id: 'changeset.reject.dialog.title' })} open={isOpen}>
      <p>{intl.formatMessage({ id: 'changeset.reject.dialog.body' })}</p>
      <Spacer />
      <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
        <Button feType="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'changeset.reject.dialog.cancel' })}
        </Button>
        <Button onClick={onConfirm}>{intl.formatMessage({ id: 'changeset.reject.dialog.confirm' })}</Button>
      </Flexbox>
    </Dialog>
  );
};

interface Props {
  isOpen: boolean;
  onClose: any;
  onConfirm: any;
}

export default RejectDialog;
