import { Select, useToast } from '@skf-internal/ui-components-react-legacy';
import { BaseSeverity } from '@skf-internal/ui-components-react-legacy/dist/esm/common/types/severity';
import { useEffect, useState } from 'react';
import { GetSelectOptions } from 'services/api';
import { ObjectType } from '../../../../domain/shared/ObjectId';

const SelectWrapper = ({ objectType, value, initialValue, fieldName, label, required, disabled, productCategory, gearboxType, editMode, hint, severity, onValueChange }: Props) => {
  const { addToast } = useToast();
  const [options, setOptions] = useState([{ label: !!initialValue ? initialValue : value?.toString(), value: value }]);

  const onChange = (value?: string | number) => {
    const label = options.find((x) => x.value === value)?.label;
    onValueChange(value, label);
  };

  useEffect(() => {
    if (editMode) {
      let isSubscribed = true;

      const fetch = async () => {
        const response = await GetSelectOptions(fieldName, objectType, { productCategory, gearboxType });
        if (isSubscribed) {
          setOptions(response.data);
        }
      };

      fetch().catch((error) => {
        addToast({ children: error.message, feSeverity: 'error' });
        console.log(error.message);
      });

      return () => {
        isSubscribed = false;
      };
    }
  }, [editMode, gearboxType]);

  return (
    <div>
      <Select required={required} disabled={!editMode || disabled} feLabel={label} fePlaceholder="Select one" multiple={false} feItems={options} value={value} onChange={onChange} feHint={hint} feSeverity={severity} feRows={7} />
    </div>
  );
};

interface Props {
  objectType: ObjectType;
  value?: string | number;
  initialValue?: string;
  fieldName: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  productCategory?: string;
  gearboxType?: string;
  editMode: boolean;
  hint?: string;
  severity?: BaseSeverity;
  onValueChange: any;
}

export default SelectWrapper;
