import { useIntl } from 'react-intl';
import { Button, Dialog, Flexbox, Spacer, Spacings } from '@skf-internal/ui-components-react-legacy';
import { ChangesetDetails, PositionsCategory, ProductCategory, RecordCategory, RelationshipProduct } from 'domain/index';
import PartForm from '../form/PartForm';
import { useState } from 'react';
import { useParams } from 'react-router';

const ListOfPartsDialog = ({ entityId, recordCategory, productCategory, positionCategory, partToEdit, indexToEdit, changesetDetails, isOpen, onFormChange, onSave, onSaveAndClose, onCancel, formError }: Props) => {
  const intl = useIntl();
  const saveIsDisabled = (): boolean => formError.size > 0;
  const [listOfAddedItems, setListOfAddedItems] = useState<any>([]);
  const changesetId = useParams();

  return (
    <Dialog
      cssWidth={changesetId.changesetId && productCategory === ProductCategory.BEARING ? '80rem' : '40rem'}
      cssHeight={'42rem'}
      feInterruptive
      feTitle={!!partToEdit.recordId ? intl.formatMessage({ id: 'part.form.edit' }) : intl.formatMessage({ id: 'part.form.add' })}
      open={isOpen}
    >
      <PartForm
        entityId={entityId}
        recordCategory={recordCategory}
        changesetDetails={changesetDetails}
        positionCategory={positionCategory}
        productCategory={productCategory}
        part={partToEdit}
        onFormChange={onFormChange}
        formError={formError}
        listOfAddedItems={listOfAddedItems}
      ></PartForm>
      <Spacer />
      <div className={changesetId.changesetId && productCategory === ProductCategory.BEARING ? 'w-1/2' : ''}>
        <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
          <Button
            feType="secondary"
            onClick={() => {
              onCancel();
              setListOfAddedItems([]);
            }}
          >
            {intl.formatMessage({ id: 'part.form.cancel' })}
          </Button>
          {changesetId.changesetId && productCategory === ProductCategory.BEARING && (
            <Button
              onClick={() => {
                onSave(partToEdit, indexToEdit);
                listOfAddedItems.push(partToEdit);
              }}
              disabled={saveIsDisabled()}
            >
              {intl.formatMessage({ id: 'part.form.save' })}
            </Button>
          )}
          <Button
            onClick={() => {
              onSaveAndClose(partToEdit, indexToEdit);
              setListOfAddedItems([]);
            }}
            disabled={saveIsDisabled()}
          >
            {intl.formatMessage({ id: 'part.form.saveandclose' })}
          </Button>
        </Flexbox>
      </div>
    </Dialog>
  );
};

interface Props {
  entityId: number;
  recordCategory: RecordCategory;
  positionCategory: PositionsCategory;
  productCategory?: ProductCategory;
  partToEdit: RelationshipProduct;
  indexToEdit: number | null;
  changesetDetails?: ChangesetDetails;
  isOpen: boolean;
  onFormChange: any;
  onSave: any;
  onSaveAndClose?: any;
  onCancel: any;
  formError?: any;
}

export default ListOfPartsDialog;
