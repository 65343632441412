import { Spacer } from '@skf-internal/ui-components-react-legacy';
import ActiveChangesets from './ActiveChangesets';
import InActiveChangesets from './InActiveChangesets';

const ChangesetListPage = () => {
  return (
    <div>
      <ActiveChangesets />
      <Spacer />
      <InActiveChangesets />
    </div>
  );
};

export default ChangesetListPage;
