import { DatePicker, Icon, Icons, IconSizes } from '@skf-internal/ui-components-react-legacy';
import { DateFilter } from 'features/search/domain/SearchQuery';
import { DateFacet } from 'features/search/domain/SearchResult';
import { useFilterLabel } from 'features/search/hooks/useFilterLabel';
import { resetDateValue, setDateValue } from 'features/search/store';
import { useAppDispatch } from 'store';
import { SearchFacetProps } from '../SearchFilter';
import FilterCollapse from './FilterCollapse';

const SearchDateFacet = ({ facet, activeFacet }: SearchFacetProps<DateFacet, DateFilter>) => {
  const dispatch = useAppDispatch();
  const label = useFilterLabel(facet.field);

  const onDateChange = (value: Date[]) => {
    dispatch(
      setDateValue({
        facet,
        from: value[0] ? value[0].toISOString() : facet.minValue,
        to: value[1] ? value[1].toISOString() : facet.maxValue
      })
    );
  };

  const onResetDates = () => {
    dispatch(resetDateValue({ facet }));
  };

  return (
    <FilterCollapse defaultExpanded={activeFacet ? true : false} label={label}>
      <div className="mb-2 flex flex-row items-center">
        <DatePicker feHideLabel feLabel="Date Picker label" feRange feSize="sm" feSeverity={activeFacet ? 'warning' : 'info'} value={[new Date(facet.minValue), new Date(facet.maxValue)]} onChange={onDateChange} />
        {activeFacet && (
          <div className="cursor-pointer ml-2" onClick={onResetDates}>
            <Icon feIcon={Icons.Close} feSize={IconSizes.Md} />
          </div>
        )}
      </div>
    </FilterCollapse>
  );
};

export default SearchDateFacet;
