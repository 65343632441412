import { Card, Spacer } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router';
import { useAppSelector } from 'store';

const TicketComments = () => {
  const intl = useIntl();
  const { changesetId } = useParams();
  const changeset = useAppSelector((state) => state.changesetData.changesets.find((ch) => ch.changesetId === changesetId), shallowEqual);

  return (
    <>
      {!!changeset && (
        <Card className="mt-5" feNoPadding>
          <div className="bg-gray-600 p-3">
            <span className="text-white font-bold">{`${intl.formatMessage({ id: 'ticket.comment.heading' })}${changeset?.changesetId.slice(0, 5)}`}</span>
          </div>
          <div className="p-3">
            {!changeset.comment && !changeset.userRequest ? (
              <p>{intl.formatMessage({ id: 'ticket.comment.empty' })}</p>
            ) : (
              <>
                {changeset?.userRequest && (
                  <>
                    <p>
                      <span className="font-bold">{intl.formatMessage({ id: 'ticket.comment.request' })} </span>
                      {`${changeset.userRequest?.category}`}
                    </p>
                    <p className="whitespace-pre-line">{changeset.userRequest?.comment}</p>
                    <Spacer />
                  </>
                )}
                <p className="font-bold">{intl.formatMessage({ id: 'ticket.comment.notes' })} </p>
                <p className="whitespace-pre-line">{changeset?.comment}</p>
              </>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default TicketComments;
