import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import { Loader, Table, TableRow, useToast, Link, Icons, Button, Dialog, Heading, Icon, IconColors, Spacer, Flexbox, Spacings } from '@skf-internal/ui-components-react-legacy';
import { ChangesetDetails, StockingPoint } from 'domain/index';
import { GetStockingPointOptions } from 'services/api/entity.service';
import { useAppSelector } from 'store/index';
import StockingPointForm from './form/StockingPointForm';
import { GetChangesOnStockingPoint } from '../utils/ChangesetInfo.utility';
import _ from 'lodash';

const StockingPoints = ({ stockingPoints, productId, changesetDetails, loading, editMode, onStockingPointChange }: Props) => {
  const intl = useIntl();
  const { addToast } = useToast();
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [stockingPointOptions, setStockingPointOptions] = useState<StockingPoint[]>([]);
  const [addedStockingPoints, setAddedStockingPoints] = useState<StockingPoint[]>([]);

  useEffect(() => {
    if (editMode && _.isEmpty(stockingPointOptions)) {
      setLoadingOptions(true);
      GetStockingPointOptions()
        .then((response: AxiosResponse<StockingPoint[]>) => {
          setStockingPointOptions(response.data);
          setLoadingOptions(false);
        })
        .catch((error: Error) => {
          addToast({ children: error.message, feSeverity: 'error' });
          setLoadingOptions(false);
          console.log(error.message);
        });
    }
  }, [editMode]);

  const getChangesDescription = (): string => {
    const [added, removed] = GetChangesOnStockingPoint(productId, changesetDetails);
    let text = '';
    text = added.length > 0 ? text.concat(`Added: ${added.join(', ')}. `) : text;
    text = removed.length > 0 ? text.concat(`Removed: ${removed.join(', ')}.`) : text;
    return text;
  };

  const handleStockingPointChange = (value: StockingPoint, checked: boolean) => {
    setAddedStockingPoints((prev) => {
      return checked ? prev.concat(value) : prev.filter((x) => x.warehouseId !== value.warehouseId);
    });
  };

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: intl.formatMessage({ id: 'stockingPoint.table.id' }), scope: 'col', sortable: {} },
        { children: intl.formatMessage({ id: 'stockingPoint.table.location' }), scope: 'col', sortable: {} },
        { children: intl.formatMessage({ id: 'stockingPoint.table.name' }), scope: 'col', sortable: {} },
        { children: '', scope: 'col' }
      ]
    }
  ];

  const tableBody: TableRow[] = stockingPoints.map((item: StockingPoint) => ({
    cells: [
      { width: '30%', children: item.warehouseId, scope: 'col' },
      { width: '30%', children: item.area, scope: 'col' },
      { width: '30%', children: item.warehouseName, scope: 'col' },
      {
        width: '10%',
        children: (
          <div className="text-right">
            {editMode && <Link as="button" aria-label={intl.formatMessage({ id: 'stockingPoint.table.delete' })} feIcon={{ feIcon: Icons.Trash, position: 'left' }} children="" onClick={() => onStockingPointChange(item, false)} />}
          </div>
        )
      }
    ]
  }));

  if (loading || loadingOptions) {
    return <Loader />;
  }

  return (
    <>
      <Heading as="h2">{`${intl.formatMessage({ id: 'stockingPoint.heading' })}`}</Heading>
      {_.some(stockingPoints) ? <Table className="mt-5 mb-5" feBody={tableBody} feHead={tableHead} /> : <p className="mb-5">{`${intl.formatMessage({ id: 'stockingPoint.table.empty' })}`}</p>}
      {editMode && (
        <div className="w-full flex justify-end">
          <Button
            className="ml-auto mr-0"
            feType="secondary"
            feIcon={{ feIcon: Icons.Plus, position: 'left' }}
            onClick={() => {
              setFormIsOpen(true);
            }}
          >
            {intl.formatMessage({ id: 'stockingPoint.button.add' })}
          </Button>
        </div>
      )}
      {!!getChangesDescription() && (
        <p className="text-brand">
          <Icon className="align-top mr-2" feIcon={Icons.InfoCircleFilled} feColor={IconColors.Brand} />
          {getChangesDescription()}
        </p>
      )}
      <Dialog cssWidth={'40rem'} feInterruptive feTitle={intl.formatMessage({ id: 'stockingPoint.form.add' })} open={formIsOpen}>
        <StockingPointForm
          stockingPointOptions={stockingPointOptions.filter((x) => !_.some(stockingPoints, (y) => y.warehouseId === x.warehouseId))}
          stockingPoints={addedStockingPoints}
          onFormChange={(item: StockingPoint, checked: boolean) => handleStockingPointChange(item, checked)}
        ></StockingPointForm>
        <Spacer />
        <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
          <Button
            feType="secondary"
            onClick={() => {
              setFormIsOpen(false);
              setAddedStockingPoints([]);
            }}
          >
            {intl.formatMessage({ id: 'stockingPoint.form.cancel' })}
          </Button>
          <Button
            onClick={() => {
              addedStockingPoints.forEach((stockingPoint) => {
                onStockingPointChange(stockingPoint, true);
              });
              setFormIsOpen(false);
              setAddedStockingPoints([]);
            }}
          >
            {intl.formatMessage({ id: 'stockingPoint.form.save' })}
          </Button>
        </Flexbox>
      </Dialog>
    </>
  );
};

interface Props {
  stockingPoints: StockingPoint[];
  changesetDetails?: ChangesetDetails;
  productId: number;
  loading: boolean;
  editMode: boolean;
  onStockingPointChange: any;
}

export default StockingPoints;
