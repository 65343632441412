import { Card, Divider, Heading, Search, Select } from '@skf-internal/ui-components-react-legacy';
import { Changeset } from 'domain/index';
import ChangesetTable, { PaginationProps } from '../table/ChangesetTable';
import CoverLoader from 'components/interaction/CoverLoader';

interface ChangesetListBoxProps {
  statuses: { label: string; value: string }[];
  loading: boolean;
  status: string;
  numberOfChangesets: number;
  query: string;
  onChangeStatus: (value: string) => void;
  onSearch: (query: string) => void;
  changesets: Changeset[];
  heading: string;
  onSort?: (column: string) => void;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  pagination?: PaginationProps;
}

const ChangesetListBox = ({ heading, numberOfChangesets, query, status, statuses, changesets, loading, onSearch, onChangeStatus, onSort, sortField, sortDirection, pagination }: ChangesetListBoxProps) => {
  return (
    <div>
      <Card>
        <Heading as="h1">
          {heading} ({numberOfChangesets})
        </Heading>
        <Divider />
        <div className="flex">
          <Search className="w-88" feLabel={'Search'} feHideLabel placeholder="Search" feResetButton={{ 'aria-label': 'Clean', onClick: () => onSearch('') }} value={query} onChange={(event: any, value: string) => onSearch(value)} />
          <Select className="w-44 ml-4" feLabel={''} feHideLabel multiple={false} feItems={statuses} value={status} onChange={(value: string) => onChangeStatus(value)} />
        </div>
        <div className="relative">
          <ChangesetTable onSort={onSort} changesets={changesets} sortField={sortField} sortDirection={sortDirection} pagination={pagination} />
          <CoverLoader loading={loading} />
        </div>
      </Card>
    </div>
  );
};

export default ChangesetListBox;
