import { Card } from '@skf-internal/ui-components-react-legacy';
import TwoColumnLayout from 'components/layout/TwoColumnLayout';
import BookmarkCard from 'features/ticket-layout/BookmarkCard';
import SearchFilter from './components/SearchFilter';
import SearchInput from './components/SearchInput';
import SearchResult from './components/SearchResult';
import useLocationSearch from './hooks/useLocationSearch';
import useSearchError from './hooks/useSearchError';

const SearchPage = () => {
  useLocationSearch();
  useSearchError();
  return (
    <TwoColumnLayout
      isSecure
      left={
        <>
          <SearchFilter />
          <BookmarkCard />
        </>
      }
      right={
        <>
          <Card className="mb-5">
            <SearchInput />
          </Card>
          <SearchResult />
        </>
      }
    />
  );
};

export default SearchPage;
