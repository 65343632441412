import { Spacer, TextAreaField, TextField } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { BaseSeverity } from '@skf-internal/ui-components-react-legacy/dist/esm/common/types/severity';
import { BaseProduct, ChangesetDetails, ProductCategory } from 'domain/index';
import { GetHint, GetSeverity, ShowApplication, ShowManufacturer, ShowProductType, SelectWrapper, ShowRemanufacturing } from '../../shared/index';
import { GetTranslationIdForEntityCategory } from 'utils';
import { ObjectType } from '../../../../domain/shared/ObjectId';

const BaseForm = ({ product, editMode, onBaseProductChange, formError, changesetDetails }: ProductProps) => {
  const intl = useIntl();
  const onDesignationChange = (event: any, value: string) => {
    onBaseProductChange({ ...product, designation: value });
  };
  const onCategoryChange = (event: any, value: string) => {
    onBaseProductChange({ ...product, productCategory: value });
  };
  const onProductTypeChange = (value: string) => {
    onBaseProductChange({ ...product, productType: value });
  };
  const onApplicationChange = (value: string) => {
    onBaseProductChange({ ...product, application: value });
  };
  const onPropertiesChange = (value: string) => {
    onBaseProductChange({ ...product, properties: value });
  };
  const onManufacturerChange = (value: string) => {
    onBaseProductChange({ ...product, manufacturer: value });
  };
  const onDesignChange = (event: any, value: string) => {
    onBaseProductChange({ ...product, design: value });
  };
  const onOtherFeaturesChange = (event: any, value: string) => {
    onBaseProductChange({ ...product, otherFeatures: value });
  };
  const onProductInformationChange = (event: any, value: string) => {
    onBaseProductChange({ ...product, productInformation: value });
  };
  const onRemarkChange = (event: any, value: string) => {
    onBaseProductChange({ ...product, remark: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(product.productId, formError, fieldName, changesetDetails);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHint(product.productId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap ">
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          required
          disabled={!editMode}
          onChange={onDesignationChange}
          value={product.designation}
          feLabel={intl.formatMessage({ id: 'baseProduct.designation' })}
          feHint={getHint('designation')}
          feSeverity={getSeverity('designation')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          required
          disabled
          onChange={onCategoryChange}
          value={intl.formatMessage({ id: GetTranslationIdForEntityCategory(product.productCategory) })}
          feLabel={intl.formatMessage({ id: 'baseProduct.productCategory' })}
          feHint={getHint('productCategory')}
          feSeverity={getSeverity('productCategory')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          required={product.productCategory === ProductCategory.COUPLING || product.productCategory === ProductCategory.BEARING}
          value={product.productType}
          fieldName="productType"
          label={intl.formatMessage({ id: 'baseProduct.productType' })}
          productCategory={product.productCategory}
          onValueChange={onProductTypeChange}
          editMode={editMode}
          disabled={!ShowProductType(product.productCategory)}
          hint={getHint('productType')}
          severity={getSeverity('productType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={product.application}
          fieldName="application"
          label={intl.formatMessage({ id: 'baseProduct.application' })}
          productCategory={product.productCategory}
          onValueChange={onApplicationChange}
          editMode={editMode}
          disabled={!ShowApplication(product.productCategory)}
          hint={getHint('application')}
          severity={getSeverity('application')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={product.properties}
          fieldName="properties"
          label={intl.formatMessage({ id: 'baseProduct.properties' })}
          productCategory={product.productCategory}
          onValueChange={onPropertiesChange}
          editMode={editMode}
          disabled={ShowRemanufacturing(product.productCategory)}
          hint={getHint('properties')}
          severity={getSeverity('properties')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          required={product.productCategory !== ProductCategory.LUBRICATOR}
          value={product?.manufacturer}
          fieldName="manufacturer"
          label={intl.formatMessage({ id: 'baseProduct.manufacturer' })}
          productCategory={product.productCategory}
          onValueChange={onManufacturerChange}
          editMode={editMode}
          disabled={!ShowManufacturer(product.productCategory)}
          hint={getHint('manufacturer')}
          severity={getSeverity('manufacturer')}
        />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextField disabled={!editMode} onChange={onDesignChange} value={product.design} feLabel={intl.formatMessage({ id: 'baseProduct.design' })} feHint={getHint('design')} feSeverity={getSeverity('design')} />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onOtherFeaturesChange}
          value={product.otherFeatures}
          feLabel={intl.formatMessage({ id: 'baseProduct.otherFeatures' })}
          feHint={getHint('otherFeatures')}
          feSeverity={getSeverity('otherFeatures')}
        />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onProductInformationChange}
          value={product.productInformation}
          feLabel={intl.formatMessage({ id: 'baseProduct.productInformation' })}
          feHint={getHint('productInformation')}
          feSeverity={getSeverity('productInformation')}
        />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextAreaField rows={5} disabled={!editMode} onChange={onRemarkChange} value={product.remark} feLabel={intl.formatMessage({ id: 'baseProduct.remark' })} feHint={getHint('remark')} feSeverity={getSeverity('remark')} />
        <Spacer />
      </div>
    </div>
  );
};

export default BaseForm;

interface ProductProps {
  product: BaseProduct;
  editMode: boolean;
  onBaseProductChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}
