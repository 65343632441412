import { Card, Heading, Loader, Spacer, Tabs, useToast } from '@skf-internal/ui-components-react-legacy';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from 'store/index';
import { GetEntityUrl, GetTranslationIdForEntityCategory, ListComparison, ObjectComparison } from 'utils/index';
import { BaseProduct, Bearing, Grease, LubricationSystem, Lubricator, ProductCategory, Seal, State, StockingPoint } from 'domain/index';
import { ExistsInChangesetInfo, FormActions, getChangesetDetails, Header, LogPosts, RemovedOverlay, resetChangesetDetailsState, SetNumberFieldErrors, SetRequiredError, SetStringMaxErrors } from '../shared/index';
import {
  BaseForm,
  BearingForm,
  GetHasChangesInDetails,
  GetHasChangesOnStockingPoint,
  getRelatedGearboxesForComponent,
  getRelatedGeneratorsForComponent,
  getRelatedTurbines,
  getStockingPoints,
  GreaseForm,
  LubricationSystemForm,
  LubricatorForm,
  RelationshipTable,
  resetGearboxesState,
  resetGeneratorsState,
  resetRelationsshipTurbinesState,
  resetStockingPoints,
  resetStockingPointsState,
  SealForm,
  setStockingPoints,
  StockingPoints
} from './index';
import { deleteProduct, editInTicket, getProduct, resetProductState, resetWarning, saveChanges, setEditMode, setProduct } from './store/product-slice';
import { getLog, resetLogState } from '../shared';
import _ from 'lodash';
import EntityStateIndicator from '../shared/entity-state/EntityStateIndicator';
import { fromProductId, ObjectType } from '../../../domain/shared/ObjectId';
import EditWarnings from '../shared/edit-errors/EditWarnings';

const MAX_VALID_NUMBER = 10000;

const EditProduct = ({ newMode, setLoadForms, entityCreationFlow }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const productId = Number(id);
  const { changesetId } = useParams();
  const [searchParams] = useSearchParams();
  const { product, productOriginal, eTag, editMode, loading, initError, error, warnings } = useAppSelector((state) => state.editProductData);
  const { loading: loadingLogPosts, logPosts, logPostsAreInitialized, error: errorLogPosts } = useAppSelector((state) => state.logData);
  const { loading: loadingTurbines, turbines, turbinesAreInitialized, error: errorTurbines } = useAppSelector((state) => state.relationshipTurbinesData);
  const { loading: loadingGenerators, generators, generatorsAreInitialized, error: errorGenerators } = useAppSelector((state) => state.relationshipGeneratorsData);
  const { loading: loadingGearboxes, gearboxes, gearboxesAreInitialized, error: errorGearboxes } = useAppSelector((state) => state.relationshipGearboxesData);
  const { loading: loadingStockingPoints, stockingPoints, stockingPointsOriginal, stockingPointsAreInitialized, error: errorStockingPoints } = useAppSelector((state) => state.stockingPointsData);
  const { changesetDetails, error: errorchangesetDetails } = useAppSelector((state) => state.changesetDetailsData);
  const changesets = useAppSelector((state) => state.changesetData.changesets);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());
  const currentEtag = useAppSelector((state) => state.changesetData.currentETag) || '';

  useEffect(() => {
    dispatch(resetLogState());
    dispatch(resetRelationsshipTurbinesState());
    dispatch(resetStockingPointsState());
    dispatch(resetGeneratorsState());
    dispatch(resetGearboxesState());
    if (newMode === false) {
      updateChangesetDetails(changesetId);
      dispatch(resetProductState());
      dispatch(getProduct({ productId, changesetId: changesetId }));
    }
  }, [productId, changesetId, newMode]);

  useEffect(() => {
    if (newMode) {
      dispatch(setEditMode({ editMode: true }));
    } else {
      dispatch(setEditMode({ editMode: !!searchParams.get('editMode') }));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!!error) handleError(error);
    if (!!errorLogPosts) handleError(errorLogPosts);
    if (!!errorTurbines) handleError(errorTurbines);
    if (!!errorGenerators) handleError(errorGenerators);
    if (!!errorGearboxes) handleError(errorGearboxes);
    if (!!errorchangesetDetails) handleError(errorchangesetDetails);
    if (!!errorStockingPoints) handleError(errorStockingPoints);
  }, [error, errorLogPosts, errorTurbines, errorGenerators, errorGearboxes, errorchangesetDetails, errorStockingPoints]);

  const handleError = (message: string) => {
    addToast({ children: message, feSeverity: 'error' });
    console.log(message);
  };

  const updateChangesetDetails = (changesetId: string | undefined) => {
    if (changesetId) {
      dispatch(getChangesetDetails({ changesetId }));
    } else {
      dispatch(resetChangesetDetailsState());
    }
  };

  if (loading.product) {
    return <Loader className="mr-auto ml-auto" />;
  } else if (initError) {
    return <p className="p-5">{intl.formatMessage({ id: 'editProductPage.error' })}</p>;
  }

  const isAutoCommit = (): boolean => (newMode === true ? false : !changesetId);

  const handleBaseProductChange = (value: BaseProduct) => {
    setFormError((prev) => SetRequiredError(value.designation, 'designation', prev, intl));
    setFormError((prev) => SetRequiredError(value.productCategory, 'productCategory', prev, intl));
    setFormError((prev) => SetStringMaxErrors(value.remark, 'remark', 500, prev, intl));
    if (product.productCategory !== ProductCategory.LUBRICATOR) {
      setFormError((prev) => SetRequiredError(value.manufacturer, 'manufacturer', prev, intl));
    }
    if (product.productCategory === ProductCategory.BEARING || product.productCategory === ProductCategory.COUPLING) {
      setFormError((prev) => SetRequiredError(value.productType, 'productType', prev, intl));
    }
    dispatch(setProduct({ product: value }));
  };

  const handleBearingChange = (value: Bearing) => {
    setFormError((prev) => SetNumberFieldErrors(value.outerDiameter, 'outerDiameter', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.innerDiameter, 'innerDiameter', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.width, 'width', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.weight, 'weight', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.numberOfRe, 'numberOfRe', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.dynamicCapacity, 'dynamicCapacity', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.staticCapacity, 'staticCapacity', MAX_VALID_NUMBER, prev, intl));

    dispatch(setProduct({ product: value }));
  };

  const handleGreaseChange = (value: Grease) => {
    dispatch(setProduct({ product: value }));
  };

  const handleLubricationSystemChange = (value: LubricationSystem) => {
    setFormError((prev) => SetNumberFieldErrors(value.weight, 'weight', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.lubSNoOfLubePoints, 'lubSNoOfLubePoints', MAX_VALID_NUMBER, prev, intl));

    dispatch(setProduct({ product: value }));
  };

  const handleLubricatorChange = (value: Lubricator) => {
    setFormError((prev) => SetNumberFieldErrors(value.lubVolumeCapacity, 'lubVolumeCapacity', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.lubMaxOperPressure, 'lubMaxOperPressure', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.lubMaxFeedLineLen, 'lubMaxFeedLineLen', MAX_VALID_NUMBER, prev, intl));

    dispatch(setProduct({ product: value }));
  };

  const handleSealChange = (value: Seal) => {
    setFormError((prev) => SetNumberFieldErrors(value.innerDiameter, 'innerDiameter', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.outerDiameter, 'outerDiameter', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.width, 'width', MAX_VALID_NUMBER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.weight, 'weight', MAX_VALID_NUMBER, prev, intl));

    dispatch(setProduct({ product: value }));
  };

  const onTicketActionClick = async (changesetId: string, action: 'edit' | 'open') => {
    let currentChangesetId: string | undefined = changesetId;
    if (action === 'edit') {
      currentChangesetId = await dispatch(editInTicket({ entityId: productId, productName: product.designation ?? '', productCategory: product.productCategory, changesetId })).unwrap();
    }
    navigate(GetEntityUrl(fromProductId(productId), currentChangesetId, action === 'edit'));
  };

  const onSave = async (event: any) => {
    const productDiff = ObjectComparison(product, productOriginal);
    const [addStockingPoints, removeStockingPoints] = ListComparison(stockingPoints, stockingPointsOriginal);
    const response = await dispatch(saveChanges({ entityId: productId, changesetId, productDiff, addStockingPoints, removeStockingPoints, isAutoCommit: isAutoCommit(), etag: newMode && changesetId ? currentEtag : eTag, newMode })).unwrap();
    if (response?.status === 'ok') {
      addToast({ children: 'Product was saved successfully', feSeverity: 'success' });
      const redirectUrl = isAutoCommit() ? `/product/${response.entityId}` : `/product/${response.entityId}/${response.changesetId}`;
      if (entityCreationFlow === false) {
        navigate(redirectUrl);
      }
    }
  };

  const onDelete = () => dispatch(deleteProduct({ changesetId, productId: product.productId, eTag }));

  const onClose = (event: any) => {
    navigate(GetEntityUrl(fromProductId(product.productId)));
  };

  const displayProductDetails = (): Array<{ id: string; children: JSX.Element; label: string }> => {
    if (product.productCategory === ProductCategory.COUPLING || product.productCategory === ProductCategory.HOUSING) {
      return [
        {
          id: '1',
          children: (
            <StockingPoints
              productId={productId}
              loading={loadingStockingPoints}
              editMode={editMode}
              changesetDetails={changesetDetails}
              stockingPoints={stockingPoints}
              onStockingPointChange={(value: StockingPoint, checked: boolean) => dispatch(setStockingPoints({ value, checked }))}
            />
          ),
          label: `${intl.formatMessage({ id: 'stockingPoint.tab' }).toUpperCase()}${GetHasChangesOnStockingPoint(productId, changesetDetails) ? ` *` : ''}`
        },
        {
          id: '2',
          children: (
            <>
              {loadingGearboxes ? (
                <Loader />
              ) : (
                <RelationshipTable
                  objectType={ObjectType.GEARBOX}
                  showTurbines={true}
                  relations={gearboxes.map((x) => {
                    return {
                      metadata: x.metadata,
                      id: x.objectId,
                      name: x.gearbox.gearboxName,
                      position: x.positionCode,
                      manufacturer: x.gearbox.manufacturer?.manufacturerName,
                      turbines: x.gearbox.turbines?.map((y) => {
                        return { id: y.turbineId, name: y.turbineName };
                      })
                    };
                  })}
                  editMode={editMode}
                />
              )}
            </>
          ),
          label: intl.formatMessage({ id: 'editProduct.tab.gearboxes' }).toUpperCase()
        },
        {
          id: '3',
          children: (
            <>
              {loadingGenerators ? (
                <Loader />
              ) : (
                <RelationshipTable
                  objectType={ObjectType.GENERATOR}
                  showTurbines={true}
                  relations={generators.map((x) => {
                    return {
                      metadata: x.metadata,
                      id: x.objectId,
                      name: x.generator.generatorName,
                      position: x.positionCode,
                      manufacturer: x.generator.manufacturer?.manufacturerName,
                      turbines: x.generator.turbines?.map((y) => {
                        return { id: y.turbineId, name: y.turbineName };
                      })
                    };
                  })}
                  editMode={editMode}
                />
              )}
            </>
          ),
          label: intl.formatMessage({ id: 'editProduct.tab.generators' }).toUpperCase()
        },
        {
          id: '4',
          children: (
            <>
              {loadingTurbines ? (
                <Loader />
              ) : (
                <RelationshipTable
                  objectType={ObjectType.TURBINE}
                  showTurbines={false}
                  relations={turbines.map((x) => {
                    return { metadata: x.metadata, id: x.objectId, name: x.turbine.turbineName, position: x.positionCode, manufacturer: x.turbine.manufacturer?.manufacturerName, turbines: [] };
                  })}
                  editMode={editMode}
                />
              )}
            </>
          ),
          label: intl.formatMessage({ id: 'editProduct.tab.turbines' }).toUpperCase()
        },
        {
          id: '5',
          children: <LogPosts logPosts={logPosts} loading={loadingLogPosts} />,
          label: intl.formatMessage({ id: 'log.tab' }).toUpperCase()
        }
      ];
    } else {
      return [
        {
          children: getSpecificProductForm(),
          id: '0',
          label: `${intl.formatMessage({ id: GetTranslationIdForEntityCategory(product.productCategory) }).toUpperCase()} ${intl.formatMessage({ id: 'details' }).toUpperCase()}${
            GetHasChangesInDetails(productId, product.productCategory, changesetDetails) ? ` *` : ''
          }`
        },
        {
          id: '1',
          children: (
            <StockingPoints
              productId={productId}
              loading={loadingStockingPoints}
              editMode={editMode}
              changesetDetails={changesetDetails}
              stockingPoints={stockingPoints}
              onStockingPointChange={(value: StockingPoint, checked: boolean) => dispatch(setStockingPoints({ value, checked }))}
            />
          ),
          label: `${intl.formatMessage({ id: 'stockingPoint.tab' }).toUpperCase()}${GetHasChangesOnStockingPoint(productId, changesetDetails) ? ` *` : ''}`
        },
        {
          id: '2',
          children: (
            <>
              {loadingGearboxes ? (
                <Loader />
              ) : (
                <RelationshipTable
                  objectType={ObjectType.GEARBOX}
                  showTurbines={true}
                  relations={gearboxes.map((x) => {
                    return {
                      metadata: x.metadata,
                      id: x.objectId,
                      name: x.gearbox.gearboxName,
                      position: x.positionCode,
                      manufacturer: x.gearbox.manufacturer?.manufacturerName,
                      turbines: x.gearbox.turbines?.map((y) => {
                        return { id: y.turbineId, name: y.turbineName };
                      })
                    };
                  })}
                  editMode={editMode}
                />
              )}
            </>
          ),
          label: intl.formatMessage({ id: 'editProduct.tab.gearboxes' }).toUpperCase()
        },
        {
          id: '3',
          children: (
            <>
              {loadingGenerators ? (
                <Loader />
              ) : (
                <RelationshipTable
                  objectType={ObjectType.GENERATOR}
                  showTurbines={true}
                  relations={generators.map((x) => {
                    return {
                      metadata: x.metadata,
                      id: x.objectId,
                      name: x.generator.generatorName,
                      position: x.positionCode,
                      manufacturer: x.generator.manufacturer?.manufacturerName,
                      turbines: x.generator.turbines?.map((y) => {
                        return { id: y.turbineId, name: y.turbineName };
                      })
                    };
                  })}
                  editMode={editMode}
                />
              )}
            </>
          ),
          label: intl.formatMessage({ id: 'editProduct.tab.generators' }).toUpperCase()
        },
        {
          id: '4',
          children: (
            <>
              {loadingTurbines ? (
                <Loader />
              ) : (
                <RelationshipTable
                  objectType={ObjectType.TURBINE}
                  showTurbines={false}
                  relations={turbines.map((x) => {
                    return { metadata: x.metadata, id: x.objectId, name: x.turbine.turbineName, position: x.positionCode, manufacturer: x.turbine.manufacturer?.manufacturerName, turbines: [] };
                  })}
                  editMode={editMode}
                />
              )}
            </>
          ),
          label: intl.formatMessage({ id: 'editProduct.tab.turbines' }).toUpperCase()
        },
        {
          id: '5',
          children: <LogPosts logPosts={logPosts} loading={loadingLogPosts} />,
          label: intl.formatMessage({ id: 'log.tab' }).toUpperCase()
        }
      ];
    }
  };

  const getSpecificProductForm = (): JSX.Element => {
    switch (product.productCategory) {
      case ProductCategory.BEARING:
        return <BearingForm bearing={product} editMode={editMode} onBearingChange={handleBearingChange} formError={formError} changesetDetails={changesetDetails} />;
      case ProductCategory.GREASE:
        return <GreaseForm grease={product} editMode={editMode} onGreaseChange={handleGreaseChange} changesetDetails={changesetDetails} formError={formError} />;
      case ProductCategory.LUBRICATION_SYSTEM:
        return <LubricationSystemForm changesetDetails={changesetDetails} lubricationSystem={product} editMode={editMode} onLubricationSystemChange={handleLubricationSystemChange} formError={formError} />;
      case ProductCategory.LUBRICATOR:
        return <LubricatorForm changesetDetails={changesetDetails} lubricator={product} editMode={editMode} onLubricatorChange={handleLubricatorChange} formError={formError} />;
      case ProductCategory.SEAL:
        return <SealForm changesetDetails={changesetDetails} seal={product} editMode={editMode} onSealChange={handleSealChange} formError={formError} />;
      case ProductCategory.COUPLING:
      case ProductCategory.HOUSING:
      default:
        return <></>;
    }
  };

  const onCancelAdd = () => {
    setLoadForms(false);
  };

  return (
    <>
      <EditWarnings entityId={productId} warnings={warnings} closeWarning={(warning) => dispatch(resetWarning(warning))} />
      <Card feNoPadding>
        <div className="relative">
          <RemovedOverlay show={changesetDetails?.refs.find((x) => x.id === productId)?.state === State.DELETED} />
          <div className={!changesetId ? 'p-8' : ''}>
            <Header changesetId={changesetId} objectId={fromProductId(productId)} entityCreationFlow={entityCreationFlow}></Header>
            <div className={changesetId ? 'p-8' : ''}>
              <div className="flex pl-2">
                <EntityStateIndicator state={product.metadata?.state} />
                <Heading as="h1">
                  {`${intl.formatMessage({ id: GetTranslationIdForEntityCategory(product.productCategory) }).toUpperCase()}`} {product.designation ? `- ${product.designation}` : <></>}
                </Heading>
              </div>
              <Spacer />
              <BaseForm product={product} editMode={editMode} onBaseProductChange={handleBaseProductChange} formError={formError} changesetDetails={changesetDetails} />
              <Tabs
                className="mt-2 mb-4"
                onClick={(_, index) => {
                  if (index === 1 && !stockingPointsAreInitialized && newMode === false) dispatch(getStockingPoints({ productId, changesetId }));
                  if (index === 2 && !gearboxesAreInitialized && newMode === false) dispatch(getRelatedGearboxesForComponent({ id: productId, changesetId }));
                  if (index === 3 && !generatorsAreInitialized && newMode === false) dispatch(getRelatedGeneratorsForComponent({ id: productId, changesetId }));
                  if (index === 4 && !turbinesAreInitialized && newMode === false) dispatch(getRelatedTurbines({ id: productId, changesetId }));
                  if (index === 5 && !logPostsAreInitialized && newMode === false) dispatch(getLog({ type: 'Product', id: productId }));
                }}
                feItems={displayProductDetails()}
                feType="expanded"
              />
              <ExistsInChangesetInfo changesets={changesets} entityId={productId} isEditingInTicket={!!changesetId} />
              <FormActions
                entityId={productId}
                editMode={editMode}
                formError={formError}
                loading={{ delete: loading.delete, editInTicket: loading.editInTicket, save: loading.save }}
                formHasChanges={!(_.isEqual(product, productOriginal) && _.isEqual(stockingPoints, stockingPointsOriginal))}
                changesetId={changesetId}
                onTicketActionClick={onTicketActionClick}
                onEditClick={() => dispatch(setEditMode({ editMode: true }))}
                onSaveClick={onSave}
                onCloseChangesetClick={onClose}
                onDeleteClick={onDelete}
                onCancelClick={() => {
                  dispatch(setEditMode({ editMode: false }));
                  dispatch(setProduct({ product: { ...productOriginal } }));
                  dispatch(resetStockingPoints());
                }}
                newMode={newMode}
                onCancelAdd={onCancelAdd}
                entityCreationFlow={entityCreationFlow}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EditProduct;

interface Props {
  newMode?: any;
  setLoadForms?: any;
  entityCreationFlow?: boolean;
}
