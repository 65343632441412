import { Heading } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import CloseEntity from '../close-entity/CloseEntity';
import CloseChangesetAndOpenEntity from './CloseChangesetAndOpenEntity';
import { ObjectId } from '../../../../domain/shared/ObjectId';
import BackToChangeset from './BackToChangeset';

const Header = ({ changesetId, objectId, entityCreationFlow }: Props) => {
  const intl = useIntl();

  return (
    <>
      {changesetId ? (
        <div className="p-8 bg-gray-100 flex justify-between items-baseline">
          <Heading as="h3">{`${intl.formatMessage({ id: 'changeset.name' })} - ${changesetId}`}</Heading>
          <div className="flex">
            {entityCreationFlow === true ||
              (entityCreationFlow === undefined && (
                <>
                  <BackToChangeset changesetId={changesetId} className="pr-2" />
                  <CloseChangesetAndOpenEntity objectId={objectId} />
                </>
              ))}
          </div>
        </div>
      ) : (
        <CloseEntity entityId={objectId.id} />
      )}
    </>
  );
};

interface Props {
  objectId: ObjectId;
  changesetId?: string;
  entityCreationFlow?: boolean;
}

export default Header;
