import { Card, Heading, Icons, Link } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { Filter } from '../domain/SearchQuery';
import { BoolFacet, DateFacet, Facet, FacetType, MultiChoiceFacet, ValueFacet } from '../domain/SearchResult';
import { resetFilters } from '../store';
import SearchBooleanFacet from './filters/SearchBooleanFacet';
import SearchDateFacet from './filters/SearchDateFacet';
import SearchMultiValueAndFacet from './filters/SearchMultiValueAndFacet';
import SearchMultiValueOrFacet from './filters/SearchMultiValueOrFacet';
import SearchValueFacet from './filters/SearchValueFacet';

export interface SearchFacetProps<TFacet, TFilter> {
  facet: TFacet;
  activeFacet?: { filter: TFilter; facet: TFacet };
}

export type CheckboxFilterProps = {
  values: { value: string; count: number; label: string }[];
  isSelected: (value: string) => boolean;
  onSelect: (value: string, checked: boolean) => void;
};

const SearchFilter = () => {
  const facets = useAppSelector((state) => state.searchData.result?.facets ?? []);
  const haveFilters = useAppSelector((state) => state.searchData.query.filters.length > 0);
  const dispatch = useAppDispatch();

  const intl = useIntl();

  const onResetFilters = () => {
    dispatch(resetFilters());
  };

  return (
    <Card className="mb-5">
      <div className="lg:flex lg:justify-between">
        <Heading as={'h4'}>{intl.formatMessage({ id: 'search.filter.header' })}</Heading>
        <Link disabled={!haveFilters} as="button" feIcon={{ feIcon: Icons.Close, position: 'left' }} onClick={onResetFilters}>
          {intl.formatMessage({ id: 'search.filter.reset' })}
        </Link>
      </div>
      {facets.map((facet: Facet, index: number) => (
        <SearchFacet key={'facet' + index} facet={facet} />
      ))}
    </Card>
  );
};

const SearchFacet = ({ facet }: { facet: Facet }) => {
  const active = useAppSelector((state) => state.searchData.query.filters.find((f) => f.facet.field === facet.field));
  if (facet.type === FacetType.MultiChoiceOrFilter) {
    return <SearchMultiValueOrFacet facet={facet as MultiChoiceFacet} activeFacet={convertActive(active)} />;
  } else if (facet.type === FacetType.MultiChoiceAndFilter) {
    return <SearchMultiValueAndFacet facet={facet as MultiChoiceFacet} activeFacet={convertActive(active)} />;
  } else if (facet.type === FacetType.ValueFilter) {
    return <SearchValueFacet facet={facet as ValueFacet} activeFacet={convertActive(active)} />;
  } else if (facet.type === FacetType.DateFilter) {
    return <SearchDateFacet facet={facet as DateFacet} activeFacet={convertActive(active)} />;
  } else if (facet.type === FacetType.BoolFilter) {
    return <SearchBooleanFacet facet={facet as BoolFacet} activeFacet={convertActive(active)} />;
  } else {
    console.log('Unknown facet', facet);
    return null;
  }
};

const convertActive = <TFacet extends Facet, TFilter extends Filter>(active?: { filter: Filter; facet: Facet }): { filter: TFilter; facet: TFacet } | undefined => {
  return active
    ? {
        filter: active.filter as TFilter,
        facet: active.facet as TFacet
      }
    : undefined;
};

export default SearchFilter;
