import { Colors, css, Icon, Icons, Shadows, Spacer, Spacings, styled } from '@skf-internal/ui-components-react-legacy';

import ComboboxInput from './ComboboxInput';
import DropdownPopper from 'components/form/ComboBox/DropdownPopper';
import { ComboBoxItem } from './ComboBoxItem';
import useComboBox from './useComboBox';
import { useEffect, useState } from 'react';
import { BaseSeverity } from '@skf-internal/ui-components-react-legacy/dist/esm/common/types/severity';

export type ComboboxProps<TItem> = {
  items: ComboBoxItem<TItem>[];
  label: string;
  isLoading: boolean;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  hint?: string;
  noResultMessage?: string;
  onItemSelected: (item: ComboBoxItem<TItem> | null | undefined) => void;
  onSearch: (value?: string) => void;
  onReset: () => void;
  currentItem?: ComboBoxItem<TItem> | null;
  className?: string;
  disableWidth?: boolean;
  severity?: BaseSeverity;
};

const ComboBox = <T,>({ items, onItemSelected, onSearch, isError, hint, disabled, required, noResultMessage, label, isLoading, errorMessage, onReset, currentItem, className, disableWidth, severity }: ComboboxProps<T>) => {
  const comboBox = useComboBox<T>(items, onItemSelected, onSearch);

  const getDisabledDropDownItem = (text: string, index: number = 0) => {
    return (
      <Styled.SelectItem key={index} highlighted={false} disabled={true}>
        <Styled.Spacer feSpacing={Spacings.Md} feHorizontal />
        {text}
      </Styled.SelectItem>
    );
  };

  const getActiveDropdownItem = (item: ComboBoxItem<T>, text: string, index: number, isHighlighted: boolean, isSelected: boolean) => {
    return (
      <Styled.SelectItem key={index} {...comboBox.getItemProps({ item, index })} highlighted={isHighlighted}>
        {isSelected ? <Styled.IconCheck feIcon={Icons.Check} /> : <Styled.Spacer feSpacing={Spacings.Md} feHorizontal />}
        {text}
        {item.feIcon && <Styled.StyledIcon feIcon={item.feIcon} />}
      </Styled.SelectItem>
    );
  };

  const getDropdownItem = (item: ComboBoxItem<T>, index: number) => {
    const selectedIndex = items.findIndex((i) => i.value === item.value);
    const isSelected = comboBox.selectedItem?.value === item.value;
    return item.disabled ? getDisabledDropDownItem(item.label, index) : getActiveDropdownItem(item, item.label, index, comboBox.highlightedIndex === selectedIndex, isSelected);
  };

  const getEmptyMessage = ({ isOpen, resultCount }: { isOpen: boolean; resultCount: number }) => {
    if (!isOpen) {
      return null;
    }
    if (!resultCount && noResultMessage) {
      return getDisabledDropDownItem(noResultMessage);
    }
    return null;
  };

  const getDropdown = () => {
    return (
      <Styled.SelectList isOpen={comboBox.isOpen && !disabled && !isError && (items.length > 0 || noResultMessage)} listWidth={!disableWidth ? buttonWidth : undefined} listHeight={'288px'} {...comboBox.getMenuProps()}>
        {comboBox.isOpen && !isError && items.length > 0 && items.map(getDropdownItem)}
        {getEmptyMessage({
          isOpen: comboBox.isOpen && !isError,
          resultCount: items.length
        })}
      </Styled.SelectList>
    );
  };

  const { selectItem, reset } = comboBox;
  useEffect(() => {
    if (currentItem !== undefined) {
      selectItem(currentItem);
      if (currentItem === null) {
        reset();
      }
    }
  }, [currentItem, selectItem, reset]);

  const [inputContainerRef, setInputContainerRef] = useState<HTMLDivElement | null>(null);
  const buttonWidth = inputContainerRef?.getBoundingClientRect().width;

  const getSearchInput = () => {
    return (
      <div ref={setInputContainerRef}>
        <ComboboxInput
          label={label}
          getComboboxProps={comboBox.getComboboxProps}
          getInputProps={comboBox.getInputProps}
          getToggleButtonProps={comboBox.getToggleButtonProps}
          isLoading={isLoading}
          isError={isError}
          errorMessage={errorMessage}
          disabled={disabled}
          required={required}
          hint={hint}
          severity={severity}
          inputValue={comboBox.inputValue}
          onResetClick={() => {
            comboBox.selectItem(null);
            comboBox.closeMenu();
            onReset();
          }}
        />
      </div>
    );
  };

  return <DropdownPopper className={className} popTrigger={getSearchInput()} poppedContent={getDropdown()} offset={isError || hint ? -21 : undefined} />;
};

const Styled = {
  SelectList: styled.ul(
    ({ isOpen, listWidth, listHeight }: { isOpen: boolean; listWidth?: string; listHeight?: string }) => css`
      display: flex;
      flex-direction: column;
      background-color: ${Colors.White};
      box-shadow: ${isOpen ? Shadows.Lg : 'none'};
      opacity: ${isOpen ? 1 : 0};
      padding: 0.25rem;
      width: ${listWidth ? listWidth + 'px' : 'auto'};
      border: ${!listWidth ? 'solid 1px grey' : 'none'};
      ${listHeight &&
      css`
        max-height: ${listHeight};
        overflow-y: auto;
      `}
    `
  ),
  SelectItem: styled.li(
    ({ highlighted, disabled }: { highlighted: boolean; disabled?: boolean }) => css`
      align-items: center;
      background-color: ${highlighted ? Colors.Primary200 : Colors.White};
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: 2.5rem;
      white-space: nowrap;
      padding-inline-end: ${Spacings.Xs};
      ${disabled &&
      css`
        color: ${Colors.Gray500};
        cursor: default;
      `}
    `
  ),
  IconCheck: styled(Icon)`
    margin-inline: ${Spacings.Xxs} ${Spacings.Xs};
  `,

  Spacer: styled(Spacer)`
    margin-inline: ${Spacings.Xxs} ${Spacings.Xs};
  `,
  StyledIcon: styled(Icon)`
    color: inherit;
    margin-left: auto;
  `
};

export default ComboBox;
