import { Button, Card, Fieldset, Loader, PasswordField, Spacer, TextField, Text, Colors } from '@skf-internal/ui-components-react-legacy';
import CenterLayout from 'components/layout/CenterLayout';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { WakeUpChangesetAPI, WakeUpComponentAPI } from 'services/api/wakeUp.service';
import { useAuth } from './AuthProvider';
import { AuthNavigationState } from './RequireAuth';

const LoginPage = () => {
  const intl = useIntl();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isSigningIn, setIsSigningIn] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const from = (location.state as AuthNavigationState)?.from?.pathname ?? '/';

  useEffect(() => {
    WakeUpComponentAPI().catch((error: Error) => console.log(error.message));
    WakeUpChangesetAPI().catch((error: Error) => console.log(error.message));
  }, []);

  const login = () => {
    setIsSigningIn(true);
    setHasError(false);
    auth.signin(
      username,
      password,
      () => {
        setIsSigningIn(false);
        navigate(from, { replace: true });
      },
      (error: string) => {
        setIsSigningIn(false);
        setHasError(true);
        setErrorMessage(error);
      }
    );
  };

  return (
    <CenterLayout>
      <Card className="w-96">
        <Fieldset feLegend={intl.formatMessage({ id: 'loginpage.legend' })}>
          <TextField feLabel={intl.formatMessage({ id: 'loginpage.username' })} required onChange={(_, value: string) => setUsername(value)} value={username} feSeverity={hasError ? 'error' : undefined} />
          <Spacer />
          <PasswordField feLabel={intl.formatMessage({ id: 'loginpage.password' })} required onChange={(_, value: string) => setPassword(value)} value={password} feSeverity={hasError ? 'error' : undefined} />
          <Spacer />
          {hasError ? (
            <>
              <Text feColor={Colors.RedBase}>{errorMessage}</Text>
              <Spacer />
            </>
          ) : null}
          <div className="flex justify-between">
            <Button onClick={login} disabled={isSigningIn}>
              {intl.formatMessage({ id: 'loginpage.submit' })}
            </Button>
            {isSigningIn ? <Loader /> : null}
          </div>
        </Fieldset>
      </Card>
    </CenterLayout>
  );
};

export default LoginPage;
