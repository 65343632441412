import { LogPost } from 'domain/log-post/LogPost';
import { Loader, Spacer, Time } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';

const LogPosts = ({ logPosts, loading }: Props) => {
  const intl = useIntl();

  return (
    <div className="h-96 mb-6 overflow-y-scroll">
      {loading && <Loader />}
      <p className="font-bold">{intl.formatMessage({ id: 'log.heading' })}</p>
      <Spacer />
      {logPosts.map((logPost) => (
        <div key={logPost.logId}>
          <Time feFormat="dateTimeShort" dateTime={logPost.logDate}></Time> | {logPost.loggedBy}
          <p className="font-bold">{!!logPost.comment ? logPost.comment : 'No comment'}</p>
          <Spacer />
        </div>
      ))}
    </div>
  );
};

interface Props {
  logPosts: LogPost[];
  loading: boolean;
}

export default LogPosts;
