import { DownloadAttachment } from '../../services/api';
import { useRef, useState } from 'react';
import { useAppSelector } from '../../store';
import { Link, useToast } from '@skf-internal/ui-components-react-legacy';

const DownloadLink = ({ changesetId, attachmentId, fileName }: Props) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const startDownload = async () => {
    setIsLoading(true);
    let objectUrl = null;
    try {
      const result = await DownloadAttachment(changesetId, attachmentId);
      objectUrl = window.URL.createObjectURL(result.data);

      ref.current!.href = objectUrl;
      ref.current?.click();
    } catch (e) {
      console.log(e);
      addToast({ children: 'Failed to download file, please try again', feSeverity: 'error' });
    } finally {
      setIsLoading(false);
      if (objectUrl) {
        window.URL.revokeObjectURL(objectUrl);
      }
    }
  };

  return (
    <>
      <a hidden download={fileName} ref={ref} target="_blank" rel="noreferrer" />
      <Link key={attachmentId} as={'button'} onClick={startDownload} disabled={isLoading}>
        {fileName}
      </Link>
    </>
  );
};

interface Props {
  changesetId: string;
  attachmentId: string;
  fileName: string;
}

export default DownloadLink;
