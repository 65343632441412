import { IconInteractive, Icons } from '@skf-internal/ui-components-react-legacy';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { removeBookmark } from 'store/index';

const CloseEntity = ({ entityId }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="flex justify-end">
      <IconInteractive
        aria-label="Close"
        as="button"
        title="Close"
        feIcon={Icons.Close}
        onClick={() => {
          dispatch(removeBookmark({ id: entityId }));
          navigate('/search');
        }}
      />
    </div>
  );
};

interface Props {
  entityId: number;
}

export default CloseEntity;
