import { Card, Divider, Icons, Link, Spacer } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import TicketComments from './ticket-comments/TicketComments';
import TicketCreate from './ticket-create/TicketCreate';
import TicketNavigation from './ticket-navigation/TicketNavigation';

const TicketCard = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card className="mb-5">
      <div className="lg:flex lg:justify-between">
        <Link as="a" feIcon={{ feIcon: Icons.Paper, position: 'left' }} href="/" onClick={(_, route) => navigate(route)}>
          {intl.formatMessage({ id: 'ticket.navigation.list' })}
        </Link>
        <TicketCreate />
      </div>
      <Spacer />
      <Divider />
      <Spacer />
      <div id="ticket-navigation" className="max-h-80 overflow-y-scroll">
        <TicketNavigation />
      </div>
      <TicketComments />
    </Card>
  );
};

export default TicketCard;
