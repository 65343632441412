import { useIntl } from 'react-intl';
import { Tag } from '@skf-internal/ui-components-react-legacy';

const UserRequestTag = ({ size = 'sm', className = 'mt-1' }: { size?: 'md' | 'sm'; className?: string }) => {
  const intl = useIntl();

  return (
    <Tag className={className} feSize={size} feColor="blue" feType="outlined">
      {intl.formatMessage({ id: 'user.request' })}
    </Tag>
  );
};

export default UserRequestTag;
