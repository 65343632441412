import { useIntl } from 'react-intl';
import { Icon, IconColors, Icons, Link, Spacer, TextAreaField, Time } from '@skf-internal/ui-components-react-legacy';
import { ChangesetDetails, Attachement } from 'domain/index';
import ChangesetStatusTag from '../../shared/ChangesetStatusTag';
import { GetNameFromEmail, IsUserRequest } from 'utils';
import DownloadLink from '../../../download/DownloadLink';
import RemoveUserRequestAttachmentButton from './RemoveUserRequestAttachmentButton';

const Info = ({ changeset, comment, editMode, onCommentChange, onRemoveUserRequestAttachment }: Props) => {
  const intl = useIntl();

  return (
    <div>
      <div className="flex pr-8 border-b-2 border-gray-200">
        <ChangesetStatusTag status={changeset.status} />
        <div className="pt-4 pb-4 pr-4">
          <span>{`${intl.formatMessage({ id: 'changeset.info.created' })}: `}</span>
          <Time dateTime={changeset.createdAt} feFormat="date" />
        </div>
        <div className="pt-4 pb-4 pr-4">
          <p>{`${intl.formatMessage({ id: 'changeset.info.createdBy' })}: ${GetNameFromEmail(changeset.createdBy)}`}</p>
        </div>
        <div className="pt-4 pb-4 pr-4">
          <p>
            {`${intl.formatMessage({ id: 'changeset.info.email' })}: `}
            <Link as="a" href={`mailto:${changeset.createdBy.email}`}>
              {changeset.createdBy.email}
            </Link>
          </p>
        </div>
      </div>
      <div className="pl-8 pr-8 flex">
        {IsUserRequest(changeset.userRequest) && (
          <div className="w-1/2">
            <Spacer />
            <p className="font-bold">{`${intl.formatMessage({ id: 'changeset.info.request' })}: `}</p>
            <Spacer />
            <p className="font-bold">{changeset.userRequest?.category}</p>
            <Spacer />
            <p className="whitespace-pre-line">{changeset.userRequest?.comment}</p>
            <Spacer />
            {changeset.userRequest?.attachments && changeset.userRequest?.attachments.length > 0 && (
              <>
                <p className="font-bold">{intl.formatMessage({ id: 'ticket.attachments.title' })}:</p>
                {changeset.userRequest?.attachments?.map((att) => (
                  <AttachementLink key={att.attachmentId} attachement={att} changesetId={changeset.changesetId} onRemoveClick={() => onRemoveUserRequestAttachment(att.attachmentId)} />
                ))}
              </>
            )}
          </div>
        )}
        <div className="w-1/2">
          <Spacer />
          <p className="font-bold">{`${intl.formatMessage({ id: 'changeset.info.notes' })}: `}</p>
          {editMode ? (
            <TextAreaField
              feHint={IsUserRequest(changeset.userRequest) ? 'Please note that this will be visible to the enduser in OneWind.' : ''}
              rows={5}
              feLabel={''}
              feHideLabel
              value={comment}
              onChange={(_, value) => onCommentChange(value)}
            />
          ) : (
            <p className="whitespace-pre-line">{comment}</p>
          )}
        </div>
      </div>
      <Spacer />
    </div>
  );
};

const AttachementLink = ({ attachement, changesetId, onRemoveClick }: { attachement: Attachement; changesetId: string; onRemoveClick: () => void }) => {
  const intl = useIntl();
  const hasUrl = attachement.url ? true : false;
  return (
    <div className="flex items-center gap-x-1.5 mt-1">
      <Icon feIcon={hasUrl ? Icons.CheckCircle : Icons.UnknownCircle} feColor={IconColors.Green} />
      {hasUrl ? (
        <>
          <DownloadLink fileName={attachement.name} attachmentId={attachement.attachmentId} changesetId={changesetId} />
          {intl.formatMessage({ id: 'ticket.attachments.bytes' }, { bytes: attachement.size })}
        </>
      ) : (
        attachement.name
      )}
      <RemoveUserRequestAttachmentButton name={attachement.name} onRemove={onRemoveClick} />
    </div>
  );
};

interface Props {
  changeset: ChangesetDetails;
  comment: string;
  editMode: boolean;
  onCommentChange: any;
  onRemoveUserRequestAttachment: (attachementId: string) => void;
}

export default Info;
