import { DatePicker, NumberField, Spacer, TextAreaField, TextField, Checkbox } from '@skf-internal/ui-components-react-legacy';
import { BaseSeverity } from '@skf-internal/ui-components-react-legacy/dist/esm/common/types/severity';
import { ChangesetDetails, Gearbox } from 'domain/index';
import SelectWrapper from 'features/edit/shared/select-wrapper/SelectWrapper';
import { GetHint, GetSeverity } from 'features/edit/shared/utils/form/Form.utility';
import { useIntl } from 'react-intl';
import { ObjectType } from '../../../../domain/shared/ObjectId';
import ManufacturerSelect from 'features/edit/shared/manufacturer-select/ManufacturerSelect';

const GearboxForm = ({ gearbox, editMode, onGearboxChange, formError, changesetDetails }: ProductProps) => {
  const intl = useIntl();
  const onGearboxNameChange = (event: any, value: string) => {
    onGearboxChange({ ...gearbox, gearboxName: value });
  };
  const onManufacturerChange = (value: number, label: string) => {
    onGearboxChange({ ...gearbox, manufacturerId: value, manufacturer: { manufacturerName: label } });
  };
  const onNominalPowerChange = (event: any, value: number | undefined) => {
    onGearboxChange({ ...gearbox, nominalPower: value });
  };
  const onGearboxTypeChange = (value: string) => {
    onGearboxChange({ ...gearbox, gearboxType: value });
  };
  const onGearboxSubTypeChange = (value: string) => {
    onGearboxChange({ ...gearbox, gearboxSubType: value });
  };
  const onNumberOfGearStepsChange = (event: any, value: number | undefined) => {
    onGearboxChange({ ...gearbox, numberOfGearSteps: value });
  };
  const onGearboxRatioChange = (event: any, value: number | undefined) => {
    onGearboxChange({ ...gearbox, gearboxRatio: value });
  };
  const onInputTurningSpeedChange = (event: any, value: number | undefined) => {
    onGearboxChange({ ...gearbox, inputTurningSpeed: value });
  };
  const onInputTorqueChange = (event: any, value: number | undefined) => {
    onGearboxChange({ ...gearbox, inputTorque: value });
  };
  const onProductionStartDateChange = (value: Date | null) => {
    onGearboxChange({ ...gearbox, productionStartDate: value });
  };
  const onProductionEndDateChange = (value: Date | null) => {
    onGearboxChange({ ...gearbox, productionEndDate: value });
  };
  const onRemarkChange = (event: any, value: string) => {
    onGearboxChange({ ...gearbox, remark: value });
  };
  const onWebsiteLinkChange = (event: any, value: string) => {
    onGearboxChange({ ...gearbox, websiteLink: value });
  };
  const onCompleteFlagChange = (event: any, value: boolean) => {
    if (value === true) {
      onGearboxChange({ ...gearbox, completeFlag: 1 });
    } else {
      onGearboxChange({ ...gearbox, completeFlag: 0 });
    }
  };
  const onDataApprovalChange = (event: any, value: boolean) => {
    if (value === true) {
      onGearboxChange({ ...gearbox, dataApproval: 1 });
    } else {
      onGearboxChange({ ...gearbox, dataApproval: 0 });
    }
  };
  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(gearbox.gearboxId, formError, fieldName, changesetDetails);
  };
  const getHint = (fieldName: string): string | undefined => {
    return GetHint(gearbox.gearboxId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap ">
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          required
          disabled={!editMode}
          onChange={onGearboxNameChange}
          value={gearbox.gearboxName}
          feLabel={intl.formatMessage({ id: 'gearbox.gearboxName' })}
          feHint={getHint('gearboxName')}
          feSeverity={getSeverity('gearboxName')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <ManufacturerSelect
          objectType={ObjectType.GEARBOX}
          labelId="gearbox.manufacturerId"
          required
          onChange={onManufacturerChange}
          editMode={editMode}
          initialValue={gearbox.manufacturer?.manufacturerName}
          value={gearbox.manufacturerId}
          hint={getHint('manufacturerName')}
          severity={getSeverity('manufacturerName')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          required
          onChange={onNominalPowerChange}
          value={gearbox.nominalPower}
          feLabel={intl.formatMessage({ id: 'gearbox.nominalPower' })}
          feHint={getHint('nominalPower')}
          feSeverity={getSeverity('nominalPower')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.GEARBOX}
          required
          value={gearbox.gearboxType}
          fieldName="gearboxType"
          label={intl.formatMessage({ id: 'gearbox.gearboxType' })}
          productCategory={'Gearbox'}
          onValueChange={onGearboxTypeChange}
          editMode={editMode}
          hint={getHint('gearboxType')}
          severity={getSeverity('gearboxType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.GEARBOX}
          value={gearbox.gearboxSubType}
          fieldName="gearboxSubType"
          label={intl.formatMessage({ id: 'gearbox.gearboxSubType' })}
          productCategory={'Gearbox'}
          gearboxType={gearbox.gearboxType}
          onValueChange={onGearboxSubTypeChange}
          editMode={editMode}
          hint={getHint('gearboxSubType')}
          severity={getSeverity('gearboxSubType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onNumberOfGearStepsChange}
          value={gearbox.numberOfGearSteps}
          feLabel={intl.formatMessage({ id: 'gearbox.numberOfGearSteps' })}
          feHint={getHint('numberOfGearSteps')}
          feSeverity={getSeverity('numberOfGearSteps')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onGearboxRatioChange}
          value={gearbox.gearboxRatio}
          feLabel={intl.formatMessage({ id: 'gearbox.gearboxRatio' })}
          feHint={getHint('gearboxRatio')}
          feSeverity={getSeverity('gearboxRatio')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onInputTurningSpeedChange}
          value={gearbox.inputTurningSpeed}
          feLabel={intl.formatMessage({ id: 'gearbox.inputTurningSpeed' })}
          feHint={getHint('inputTurningSpeed')}
          feSeverity={getSeverity('inputTurningSpeed')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onInputTorqueChange}
          value={gearbox.inputTorque}
          feLabel={intl.formatMessage({ id: 'gearbox.inputTorque' })}
          feHint={getHint('inputTorque')}
          feSeverity={getSeverity('inputTorque')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2 flex flex-wrap">
        <div className="w-1/2 pr-2">
          <DatePicker
            feRange={false}
            disabled={!editMode}
            onChange={onProductionStartDateChange}
            value={typeof gearbox.productionStartDate === 'string' ? new Date(gearbox.productionStartDate) : gearbox.productionStartDate}
            feLabel={intl.formatMessage({ id: 'gearbox.productionStartDate' })}
            feHint={getHint('productionStartDate')}
            feSeverity={getSeverity('productionStartDate')}
          />
          <Spacer />
        </div>
        <div className="w-1/2 pl-2">
          <DatePicker
            feRange={false}
            disabled={!editMode}
            onChange={onProductionEndDateChange}
            value={typeof gearbox.productionEndDate === 'string' ? new Date(gearbox.productionEndDate) : gearbox.productionEndDate}
            feLabel={intl.formatMessage({ id: 'gearbox.productionEndDate' })}
            feHint={getHint('productionEndDate')}
            feSeverity={getSeverity('productionEndDate')}
          />
          <Spacer />
        </div>
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextField disabled={!editMode} onChange={onWebsiteLinkChange} value={gearbox.websiteLink} feLabel={intl.formatMessage({ id: 'gearbox.websiteLink' })} feHint={getHint('websiteLink')} feSeverity={getSeverity('websiteLink')} />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextAreaField rows={5} disabled={!editMode} onChange={onRemarkChange} value={gearbox.remark} feLabel={intl.formatMessage({ id: 'gearbox.remark' })} feHint={getHint('remark')} feSeverity={getSeverity('remark')} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2 mt-7">
        <Checkbox feLabel={intl.formatMessage({ id: 'gearbox.completeFlag' })} checked={gearbox.completeFlag === 1} disabled={!editMode} onChange={onCompleteFlagChange} />
        <Checkbox className="mt-4" feLabel={intl.formatMessage({ id: 'gearbox.dataApproval' })} checked={gearbox.dataApproval === 1} disabled={!editMode} onChange={onDataApprovalChange} />
      </div>
    </div>
  );
};

export default GearboxForm;

interface ProductProps {
  gearbox: Gearbox;
  editMode: boolean;
  onGearboxChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}
