import { useIntl } from 'react-intl';
import { NumberField, Spacer, TextAreaField, TextField } from '@skf-internal/ui-components-react-legacy';
import DesignationComboBox, { DesignationItem } from 'features/search/DesignationComboBox';
import { useAppSelector } from 'store';
import { ObjectType } from 'domain/shared/ObjectId';
import { RelationGearbox } from 'domain/index';

const GearboxForm = ({ gearbox, onFormChange: onPartChange, formError }: Props) => {
  const intl = useIntl();

  const gearboxChanges = useAppSelector((state) => state.changesetDetailsData.changesetDetails?.refs.filter((ref) => ref.type === ObjectType.GEARBOX));
  const additionalItems = gearboxChanges?.map(
    (change) =>
      ({
        id: change.id,
        name: change.name,
        manufacturerName: '-'
      } as DesignationItem)
  );

  const onGearboxChange = (item: DesignationItem | null) => {
    if (item) {
      onPartChange({
        ...gearbox,
        objectId: item.id,
        gearboxName: item.name,
        manufacturer: { manufacturerName: item.manufacturerName },
        gearbox: {
          gearboxName: item.name,
          manufacturer: { manufacturerName: item.manufacturerName }
        }
      });
    } else {
      onPartChange({
        ...gearbox,
        objectId: null,
        gearboxName: null,
        manufacturer: null,
        gearbox: {
          gearboxName: null,
          manufacturer: { manufacturerName: null }
        }
      });
    }
  };
  const onManufacturerChange = (event: any, value: string) => {};
  const onMarketShareChange = (event: any, value: number) => {
    onPartChange({ ...gearbox, marketShare: value });
  };
  const onRemarkChange = (event: any, value: string) => {
    onPartChange({ ...gearbox, remark: value });
  };
  const getSeverity = (fieldName: string): 'error' | 'info' | 'success' | 'warning' | undefined => {
    if (formError && formError.get(fieldName)) {
      return 'error';
    }
  };
  const getHint = (fieldName: string): string | undefined => {
    if (formError && formError.get(fieldName)) {
      return formError.get(fieldName);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-1/2 pl-2 pr-2">
        <DesignationComboBox
          label={intl.formatMessage({ id: 'entity.category.gearbox' })}
          value={gearbox.objectId ? { id: gearbox.objectId, name: gearbox.gearbox?.gearboxName ?? '', manufacturerName: gearbox.gearbox?.manufacturer.manufacturerName ?? '' } : undefined}
          recordCategory={ObjectType.GEARBOX}
          onChange={onGearboxChange}
          additionalItems={additionalItems}
          severity={getSeverity('objectId')}
          hint={getHint('objectId')}
        />
        <Spacer />
      </div>
      <div className="w-1/2 pl-2 pr-2">
        <TextField disabled onChange={onManufacturerChange} value={gearbox.gearbox?.manufacturer.manufacturerName ?? ''} feLabel={intl.formatMessage({ id: 'windTurbine.manufacturer' })} />
        <Spacer />
      </div>
      <div className="w-1/2 pl-2 pr-2">
        <NumberField min={0} feHint={getHint('marketShare')} feSeverity={getSeverity('marketShare')} onChange={onMarketShareChange} value={gearbox.marketShare} feLabel={intl.formatMessage({ id: 'windTurbine.marketShare' })} />
        <Spacer />
      </div>
      <div className="w-full pl-2 pr-2">
        <TextAreaField rows={5} onChange={onRemarkChange} value={gearbox.remark} feLabel={intl.formatMessage({ id: 'windTurbine.remark' })} />
        <Spacer />
      </div>
    </div>
  );
};

interface Props {
  gearbox: RelationGearbox;
  onFormChange: any;
  formError?: Map<string, string>;
}

export default GearboxForm;
