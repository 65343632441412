import { IconInteractive, Icons } from '@skf-internal/ui-components-react-legacy';
import { useNavigate } from 'react-router';
import { GetEntityUrl } from '../../../../utils';
import { ObjectId } from '../../../../domain/shared/ObjectId';

const CloseChangesetAndOpenEntity = ({ objectId }: Props) => {
  const navigate = useNavigate();
  return (
    <IconInteractive
      aria-label="Close ticket and show committed item"
      as="button"
      title="Close ticket and show committed item"
      feIcon={Icons.Close}
      onClick={() => {
        navigate(GetEntityUrl(objectId));
      }}
    />
  );
};

interface Props {
  objectId: ObjectId;
}

export default CloseChangesetAndOpenEntity;
