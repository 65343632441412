import { Icons, Link, Loader } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { addChangeset, useAppDispatch, useAppSelector } from 'store';

const TicketCreate = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const addLoading = useAppSelector((state) => state.changesetData.addLoading);

  const createTicket = () => {
    dispatch(addChangeset((changesetId) => navigate(`/tickets/${changesetId}`)));
  };

  return addLoading ? (
    <Loader feSize="sm" />
  ) : (
    <Link as="button" feIcon={{ feIcon: Icons.Plus, position: 'left' }} onClick={() => createTicket()}>
      {intl.formatMessage({ id: 'ticket.navigation.create' })}
    </Link>
  );
};

export default TicketCreate;
