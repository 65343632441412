import { Spacer, TextField, NumberField } from '@skf-internal/ui-components-react-legacy';
import { BaseSeverity } from '@skf-internal/ui-components-react-legacy/dist/esm/common/types/severity';
import { LubricationSystem, ChangesetDetails } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetHint, GetSeverity, SelectWrapper } from '../../shared/index';
import { ObjectType } from '../../../../domain/shared/ObjectId';

const LubricationSystemForm = ({ lubricationSystem, editMode, onLubricationSystemChange, formError, changesetDetails }: Props) => {
  const intl = useIntl();
  const onWeightChange = (event: any, value: number) => {
    onLubricationSystemChange({ ...lubricationSystem, weight: value });
  };
  const onLubSSeriesRetroChange = (event: any, value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSSeriesRetro: value });
  };
  const onLubSNoOfLubePointsChange = (event: any, value: number) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSNoOfLubePoints: value });
  };
  const onLubSPumpChange = (event: any, value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSPump: value });
  };
  const onLubSSystemTypeChange = (value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSSystemType: value });
  };
  const onLubSGreaseChange = (event: any, value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSGrease: value });
  };
  const onLubSMeteringDeviceChange = (value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSMeteringDevice: value });
  };
  const onLubSPinionChange = (value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSPinion: value });
  };
  const onLubSDocsChange = (event: any, value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSDocs: value });
  };
  const onLubSPrimaryFeederChange = (event: any, value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSPrimaryFeeder: value });
  };
  const onLubSSecondaryFeederChange = (event: any, value: string) => {
    onLubricationSystemChange({ ...lubricationSystem, lubSSecondaryFeeder: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(lubricationSystem.productId, formError, fieldName, changesetDetails);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHint(lubricationSystem.productId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onLubSSeriesRetroChange}
          value={lubricationSystem.lubSSeriesRetro}
          feLabel={intl.formatMessage({ id: 'lubricationSystem.lubSSeriesRetro' })}
          feHint={getHint('lubSSeriesRetro')}
          feSeverity={getSeverity('lubSSeriesRetro')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onLubSNoOfLubePointsChange}
          value={lubricationSystem.lubSNoOfLubePoints}
          feLabel={intl.formatMessage({ id: 'lubricationSystem.lubSNoOfLubePoints' })}
          feHint={getHint('lubSNoOfLubePoints')}
          feSeverity={getSeverity('lubSNoOfLubePoints')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={lubricationSystem.lubSPump}
          fieldName="lubSPump"
          label={intl.formatMessage({ id: 'lubricationSystem.lubSPump' })}
          productCategory={lubricationSystem.productCategory}
          onValueChange={onLubSPumpChange}
          editMode={editMode}
          hint={getHint('lubSPump')}
          severity={getSeverity('lubSPump')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={lubricationSystem.lubSSystemType}
          fieldName="lubSSystemType"
          label={intl.formatMessage({ id: 'lubricationSystem.lubSSystemType' })}
          productCategory={lubricationSystem.productCategory}
          onValueChange={onLubSSystemTypeChange}
          editMode={editMode}
          hint={getHint('lubSSystemType')}
          severity={getSeverity('lubSSystemType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onLubSPrimaryFeederChange}
          value={lubricationSystem.lubSPrimaryFeeder}
          feLabel={intl.formatMessage({ id: 'lubricationSystem.lubSPrimaryFeeder' })}
          feHint={getHint('lubSPrimaryFeeder')}
          feSeverity={getSeverity('lubSPrimaryFeeder')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onLubSSecondaryFeederChange}
          value={lubricationSystem.lubSSecondaryFeeder}
          feLabel={intl.formatMessage({ id: 'lubricationSystem.lubSSecondaryFeeder' })}
          feHint={getHint('lubSSecondaryFeeder')}
          feSeverity={getSeverity('lubSSecondaryFeeder')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onLubSGreaseChange}
          value={lubricationSystem.lubSGrease}
          feLabel={intl.formatMessage({ id: 'lubricationSystem.lubSGrease' })}
          feHint={getHint('lubSGrease')}
          feSeverity={getSeverity('lubSGrease')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={lubricationSystem.lubSMeteringDevice}
          fieldName="lubSMeteringDevice"
          label={intl.formatMessage({ id: 'lubricationSystem.lubSMeteringDevice' })}
          productCategory={lubricationSystem.productCategory}
          onValueChange={onLubSMeteringDeviceChange}
          editMode={editMode}
          hint={getHint('lubSMeteringDevice')}
          severity={getSeverity('lubSMeteringDevice')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={lubricationSystem.lubSPinion}
          fieldName="lubSPinion"
          label={intl.formatMessage({ id: 'lubricationSystem.lubSPinion' })}
          productCategory={lubricationSystem.productCategory}
          onValueChange={onLubSPinionChange}
          editMode={editMode}
          hint={getHint('lubSPinion')}
          severity={getSeverity('lubSPinion')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField disabled={!editMode} onChange={onWeightChange} value={lubricationSystem.weight} feLabel={intl.formatMessage({ id: 'lubricationSystem.weight' })} feHint={getHint('weight')} feSeverity={getSeverity('weight')} min={0} />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextField disabled={!editMode} onChange={onLubSDocsChange} value={lubricationSystem.lubSDocs} feLabel={intl.formatMessage({ id: 'lubricationSystem.lubSDocs' })} feHint={getHint('lubSDocs')} feSeverity={getSeverity('lubSDocs')} />
        <Spacer />
      </div>
    </div>
  );
};

interface Props {
  lubricationSystem: LubricationSystem;
  editMode: boolean;
  onLubricationSystemChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}

export default LubricationSystemForm;
