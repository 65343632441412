import { Heading, Icons, Link } from '@skf-internal/ui-components-react-legacy';
import { Changeset } from 'domain/index';
import UserRequestTag from 'features/changeset/shared/user-request-tag/UserRequestTag';
import { useIntl } from 'react-intl';
import { ChangesetIsActive, IsUserRequest } from 'utils';

const ChangesetHeader = ({ changeset, onDelete }: Props) => {
  const intl = useIntl();

  return (
    <div className="py-8 px-4 bg-gray-100 flex justify-between items-baseline">
      <div className="flex items-center">
        {IsUserRequest(changeset.userRequest) && <UserRequestTag className="mr-2" size="md" />}
        <Heading as="h3">{`${intl.formatMessage({ id: 'changeset.name' })} - ${changeset.changesetId}`}</Heading>
      </div>
      {ChangesetIsActive(changeset.status) && !IsUserRequest(changeset.userRequest) && (
        <Link as="button" onClick={onDelete} feIcon={{ feIcon: Icons.Trash, position: 'left' }}>
          {intl.formatMessage({ id: 'changeset.delete.button' })}
        </Link>
      )}
    </div>
  );
};

interface Props {
  changeset: Changeset;
  onDelete: any;
}

export default ChangesetHeader;
