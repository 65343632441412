import { Table } from '@skf-internal/ui-components-react-legacy';
import { addBookmark, useAppDispatch, useAppSelector } from 'store';
import { Order, Sorting } from '../domain/SearchQuery';
import { ValueFacet } from '../domain/SearchResult';
import useTableBuilder from '../hooks/useTableBuilder';
import { setSorting } from '../store';
import config from './SearchConfig';
import { ObjectType } from '../../../domain/shared/ObjectId';
import { ProductCategory } from '../../../domain';

const SearchTable = () => {
  const hits = useAppSelector((state) => state.searchData.result?.hits);
  const selectedObjectType = useAppSelector((state) => ((state.searchData.result?.facets.find((f) => f.field === 'objectType') as ValueFacet)?.selected as unknown as ObjectType) ?? 'default');

  const { getHeader, getRows } = useTableBuilder(selectedObjectType, config);

  const sorting = useAppSelector((state) => state.searchData.query.sort ?? ({ order: Order.DESC } as Sorting));

  const changesets = useAppSelector((state) => state.changesetData.changesets);
  const dispatch = useAppDispatch();

  const onSort = (field: string) => {
    if (sorting.order === Order.DESC) {
      dispatch(setSorting({ field, order: Order.ASC }));
    } else {
      dispatch(setSorting({ field, order: Order.DESC }));
    }
  };

  const OnAddBookmark = (id: number, category: ObjectType | ProductCategory, name: string) => {
    dispatch(addBookmark({ id, category, name }));
  };

  return <Table className="text-sm" feCompact feBody={getRows(hits, changesets, OnAddBookmark)} feHead={getHeader(onSort)} />;
};

export default SearchTable;
